<div [id]="id + '__noticia-item-content'" class="noticia-item-content">
  <img
    [id]="id + '__noticia-item-content__img'"
    class="noticia-item-content__img"
    [src]="item.capa"
  />
  <div
    [id]="id + '__noticia-item-content__text'"
    class="noticia-item-content__text"
  >
    <h2
      [id]="id + '__noticia-item-content__text__title'"
      class="noticia-item-content__text__title"
      [innerHTML]="item.titulo"
    ></h2>
    <div
      [id]="id + '__noticia-item-content__text__group'"
      class="noticia-item-content__text__group"
    >
      <p
        [id]="id + '__noticia-item-content__text__group__subtitle'"
        class="noticia-item-content__text__group__subtitle"
        [innerHTML]="item.subtitulo"
      ></p>
      <a
        [id]="id + '__noticia-item-content__text__group__link'"
        class="noticia-item-content__text__group__link"
        >Veja a Notícia</a
      >
    </div>
    <span
      [id]="id + '__noticia-item-content__text__data'"
      class="noticia-item-content__text__data"
      ><b>EXPIRA EM:</b> {{ item.dataFinal | date : "dd/MM/yyyy" }}</span
    >
  </div>
  <div [id]="id + '__noticia-item-content__btns'" class="noticia-item-content__btns">
    <agv-button *ngIf="type === 1"
      [id]="id + '__noticia-item-content__btns__btn'"
      class="noticia-item-content__btns__btn"
      [type]="'1'"
      [text]="txtButton"
      [habilita]="true"
      (sendAction)="action()"
    ></agv-button>
    <agv-button-edit *ngIf="type === 2" (sendAction)="editar()"></agv-button-edit>
    <agv-button-exc *ngIf="type === 2" (sendAction)="excluir()"></agv-button-exc>
  </div>
</div>
