import { NoticiaModel } from '../core/models/noticias.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NoticiaService {
  private BASE_URL: string = environment.uri;

  constructor(private http: HttpClient) {}

  // Função para buscar notícias
  public getTodasNoticias(
    pag: number,
    pagLimit: number
  ): Observable<{ noticias: NoticiaModel[] }> {
    return this.http.get<{ noticias: NoticiaModel[] }>(
      `${this.BASE_URL}v1/home/noticia/todas?pag=${pag}&limit=${pagLimit}`
    );
  }

  public getTodasNoticiasSec(
    pag: number,
    pagLimit: number
  ): Observable<{ noticias: NoticiaModel[] }> {
    return this.http.get<{ noticias: NoticiaModel[] }>(
      `${this.BASE_URL}v1/home/noticia/sec/todas?pag=${pag}&limit=${pagLimit}`
    );
  }
}
