import { deletaFormularioPLA } from '../core/store/primeira-ligacao/primeira-ligacao.actions';
import { deletaEmpresaCNPJ } from '../core/store/empresa-cnpj/empresa-cnpj.actions';
import { ClienteCompleto } from '../core/models/service/cadastroCliente';
import { RequestDesligamento } from './desligamento-temporario.service';
import { ClienteService } from '../shared/observables/cliente.service';
import { DadosRepresentante } from '../core/models/empresas/empresa';
import { Categoria, Servicos } from '../core/models/guiaServicos';
import { IResponseAvatar } from '../shared/models/user.model';
import { addDays, format, isAfter, parse } from 'date-fns';
import { Fornecimento } from '../core/models/fornecimento';
import { Observable, Subject, delay, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

const TOKEN_KEY_PRIMEIRO_ACESSO = 'auth-token-primeiro-acesso';
const DESLIGAMENTO_RESQUEST = 'desligamento_request';
const DESLIGAMENTO_TITULAR = 'desligamento_titular';
const DESLIGAMENTO_RESPONSAVEL = 'dadosResponsavel';
const SHOWPRIVACYCOOKIES = 'show-privacy-cookies';
const IS_REPARCELAMENTO_KEY = 'isReparcelamento';
const CNPJ_REPRESENTANTE = 'cnpj-representante';
const REQ_REPARCELAMENTO = 'reqReparcelamento';
const CATEGORIA_SERVICO = 'categoria_servico';
const REQ_PARCELAMENTO = 'reqParcelamento';
const COD_FORNECIMENTO = 'codFornecimento';
const KEEP_CONNECTED = 'keep_connected';
const QUALIDADE_AGUA = 'qualidade_agua';
const FORNECIMENTOS = 'fornecimentos';
const DADOS_TITULAR = 'dados_titular';
const IS_USER_ADMIN = 'is_user_admin';
const USER_IMAGE_URL = 'userImageUrl';
const ENDERECO_USER = 'enderecoUser';
const DADO_EMPRESA = 'dados_empresa';
const TIPO_PESSOA_KEY = 'tipo_user';
const EXPIRES_KEY = 'auth-expire';
const TYPE_USER = 'tipo_usuario';
const FALTA_AGUA = 'falta_agua';
const AVATAR_IMG = 'avatar_img';
const DADOS_PARC = 'dadosParc';
const TOKEN_KEY = 'auth-token';
const PROTOCOLO = 'protocolo';
const ENDERECOS = 'enderecos';
const CATEGORIA = 'categoria';
const NAME_KEY = 'user-name';
const CNPJ_KEY = 'user-cnpj';
const USER_KEY = 'auth-user';
const CPF_KEY = 'user-cpf';
const CLIENTE = 'cliente';
const NOTICIA = 'noticia';
const PLA = 'PLA';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  public destroyed$ = new Subject<boolean>();
  constructor(private store: Store, private cliObs: ClienteService) {}

  signOut(): void {
    window.localStorage.removeItem(EXPIRES_KEY);
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.removeItem(AVATAR_IMG);
    window.localStorage.removeItem(DADOS_TITULAR);
    window.localStorage.removeItem(TYPE_USER);
    window.localStorage.removeItem(PLA);
    window.localStorage.removeItem(CNPJ_REPRESENTANTE);

    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(EXPIRES_KEY);
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.removeItem(AVATAR_IMG);
    window.sessionStorage.removeItem(DADOS_TITULAR);
    window.sessionStorage.removeItem(SHOWPRIVACYCOOKIES);
    window.sessionStorage.removeItem(TYPE_USER);
    window.sessionStorage.removeItem(CNPJ_REPRESENTANTE);

    this.store.dispatch(deletaEmpresaCNPJ());
    this.store.dispatch(deletaFormularioPLA());

    window.localStorage.clear();
    window.sessionStorage.clear();
  }
  public saveExternalToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
    window.sessionStorage.setItem(EXPIRES_KEY, this.getNewExpiresDate());
  }

  getNewExpiresDate(): string {
    const today = new Date();
    const newData = addDays(today, 1);

    return format(newData, 'yyyy-MM-dd HH:mm:ss');
  }

  public saveToken(auth: any, manterConectado: boolean = false): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(EXPIRES_KEY);
    window.sessionStorage.removeItem(TYPE_USER);
    window.localStorage.removeItem(EXPIRES_KEY);
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(TYPE_USER);
    window.sessionStorage.setItem(TOKEN_KEY, auth.token);
    window.sessionStorage.setItem(EXPIRES_KEY, auth.expires);
    window.sessionStorage.setItem(TYPE_USER, auth.type);
    if (manterConectado) {
      window.localStorage.setItem(TOKEN_KEY, auth.token);
      window.localStorage.setItem(EXPIRES_KEY, auth.expires);
      window.sessionStorage.setItem(TYPE_USER, auth.type);
    }
  }

  public saveTokenPrimeiroAcesso(
    auth: any,
    manterConectado: boolean = false
  ): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(EXPIRES_KEY);
    window.sessionStorage.removeItem(TYPE_USER);
    window.localStorage.removeItem(EXPIRES_KEY);
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(TYPE_USER);
    window.sessionStorage.setItem(TOKEN_KEY_PRIMEIRO_ACESSO, auth.token);
  }

  manterConectado(manterConectado: string) {
    window.localStorage.setItem(KEEP_CONNECTED, manterConectado);
  }

  removerManterConectado() {
    window.localStorage.removeItem(KEEP_CONNECTED);
  }

  saveUser(manterConectado: boolean = false): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.localStorage.removeItem(USER_KEY);
    this.cliObs?.currentCliente?.subscribe((cliente) => {
      if (cliente) {
        window.sessionStorage.setItem(USER_KEY, JSON.stringify(cliente));
        if (manterConectado) {
          window.localStorage.setItem(USER_KEY, JSON.stringify(cliente));
        }
      }
    });
  }

  public getToken(): string | null {
    return (
      window.sessionStorage.getItem(TOKEN_KEY) ||
      window.localStorage.getItem(TOKEN_KEY)
    );
  }

  public getTokenPrimeiroAcesso(): string | null {
    return (
      window.sessionStorage.getItem(TOKEN_KEY_PRIMEIRO_ACESSO) ||
      window.localStorage.getItem(TOKEN_KEY_PRIMEIRO_ACESSO)
    );
  }

  public getTipoUsuario(): string | null {
    return (
      window.sessionStorage.getItem(TYPE_USER) ||
      window.localStorage.getItem(TYPE_USER)
    );
  }

  checkToken(): boolean {
    if (this.getToken()) {
      return true;
    } else {
      return false;
    }
  }

  getManterConectado() {
    return window.localStorage.getItem(KEEP_CONNECTED);
  }

  public getUserKey(): any {
    const user =
      window.sessionStorage.getItem(USER_KEY) ??
      window.localStorage.getItem(USER_KEY);

    if (user && this.isExpired()) {
      return JSON.parse(user);
    }

    return null;
  }

  public getUser(): any {
    const user =
      window.sessionStorage.getItem(USER_KEY) ||
      window.localStorage.getItem(USER_KEY);

    if (user && this.isExpired()) {
      return JSON.parse(user);
    }

    return null;
  }

  public isExpired() {
    let expiresDate =
      window.sessionStorage.getItem(EXPIRES_KEY) ||
      window.localStorage.getItem(EXPIRES_KEY);
    if (expiresDate) {
      let date = parse(expiresDate, 'yyyy-MM-dd HH:mm:ss', new Date());
      const result = isAfter(new Date(date), Date.now());
      return result;
    } else return false;
  }

  public setUserInformation(typeInfo: string, info: string) {
    switch (typeInfo) {
      case 'cpf':
        window.sessionStorage.setItem(CPF_KEY, info);
        break;
      case 'nome':
        window.sessionStorage.setItem(NAME_KEY, info);
        break;
      case 'tipoPessoa':
        window.sessionStorage.setItem(TIPO_PESSOA_KEY, info);
        break;
      case 'cnpj':
        window.sessionStorage.setItem(CNPJ_KEY, info);
        break;
      default:
        break;
    }
  }

  public getUserInformation(infoRequired: string): string | null {
    switch (infoRequired) {
      case 'cpf':
        return window.sessionStorage.getItem(CPF_KEY);
      case 'nome':
        return window.sessionStorage.getItem(NAME_KEY);
      case 'tipoPessoa':
        return window.sessionStorage.getItem(TIPO_PESSOA_KEY);
      case 'cnpj':
        return window.sessionStorage.getItem(CNPJ_KEY);
      default:
        return null;
    }
  }

  public setUserRole(admin: boolean) {
    window.sessionStorage.setItem(IS_USER_ADMIN, admin.toString());
  }

  public getUserRole(): boolean {
    return window.sessionStorage.getItem(IS_USER_ADMIN) === 'true';
  }

  public setReparcelamentoService(): void {
    localStorage.setItem(IS_REPARCELAMENTO_KEY, 'true');
  }

  public removeReparcelamentoService(): void {
    localStorage.removeItem(IS_REPARCELAMENTO_KEY);
  }

  public getReparcelamentoService(): boolean {
    const isReparcelamento = localStorage.getItem(IS_REPARCELAMENTO_KEY);
    return isReparcelamento === 'true';
  }

  public setAvatar(avatar: IResponseAvatar) {
    localStorage.setItem(AVATAR_IMG, JSON.stringify(avatar));
  }

  public getAvatar(): IResponseAvatar | null {
    const avatar = localStorage.getItem(AVATAR_IMG);
    return avatar ? JSON.parse(avatar) : null;
  }

  public setCodFornecimento(codFornecimento: string) {
    localStorage.setItem(COD_FORNECIMENTO, codFornecimento);
  }

  public getCodFornecimento(): string | null {
    return localStorage.getItem(COD_FORNECIMENTO);
  }

  public removeCodFornecimento() {
    localStorage.removeItem(COD_FORNECIMENTO);
  }

  public setDadosTitular(
    nomeTitular: string,
    enderecoFornecimento: string,
    codForn: string
  ) {
    localStorage.setItem(
      DADOS_TITULAR,
      JSON.stringify({ nomeTitular, enderecoFornecimento, codForn })
    );
  }

  public getDadosTitular(): {
    nomeTitular: string;
    enderecoFornecimento: string;
    codForn: string;
  } | null {
    const dados = localStorage.getItem(DADOS_TITULAR);
    return dados ? JSON.parse(dados) : null;
  }

  public removeDadosTitular() {
    localStorage.removeItem(DADOS_TITULAR);
  }

  public setDadosEmpresa(nomeEmpresa: string, endereco: string) {
    localStorage.setItem(
      DADO_EMPRESA,
      JSON.stringify({ nomeEmpresa, endereco })
    );
  }

  public getDadosEmpresa(): {
    nomeEmpresa: string;
    endereco: string;
  } | null {
    const dados = localStorage.getItem(DADO_EMPRESA);
    return dados ? JSON.parse(dados) : null;
  }

  public setCliente(cliente: ClienteCompleto) {
    localStorage.setItem(CLIENTE, JSON.stringify(cliente));
  }

  public getCliente(): any {
    const cliente = localStorage.getItem(CLIENTE);
    return cliente ? JSON.parse(cliente) : null;
  }

  public setProtocolo(protocolo: string): void {
    localStorage.setItem(PROTOCOLO, protocolo);
  }

  public getProtocolo(): string {
    const protocolo = localStorage.getItem(PROTOCOLO);
    return protocolo ? protocolo : '';
  }

  public removeProtocolo(): void {
    localStorage.removeItem(PROTOCOLO);
  }

  public removeNoticia(): any {
    localStorage.removeItem(NOTICIA);
  }

  public setEnderecoUser(enderecoUser: string) {
    localStorage.setItem(ENDERECO_USER, enderecoUser);
  }

  public getEnderecoUser(): string | null {
    return localStorage.getItem(ENDERECO_USER);
  }

  public removeEnderecoUser() {
    localStorage.removeItem(ENDERECO_USER);
  }

  public setReqReparcelamento(reqReparcelamento: string) {
    localStorage.setItem(REQ_REPARCELAMENTO, reqReparcelamento);
  }

  public getReqReparcelamento(): string | null {
    return localStorage.getItem(REQ_REPARCELAMENTO);
  }

  public removeReqReparcelamento() {
    localStorage.removeItem(REQ_REPARCELAMENTO);
  }

  public setReqParcelamento(reqParcelamento: string) {
    localStorage.setItem(REQ_PARCELAMENTO, reqParcelamento);
  }

  public getReqParcelamento(): string | null {
    return localStorage.getItem(REQ_PARCELAMENTO);
  }

  public removeReqParcelamento() {
    localStorage.removeItem(REQ_PARCELAMENTO);
  }

  public setDadosParc(dadosParc: string) {
    localStorage.setItem(DADOS_PARC, dadosParc);
  }

  public getDadosParc(): string | null {
    return localStorage.getItem(DADOS_PARC);
  }

  public removeDadosParc() {
    localStorage.removeItem(DADOS_PARC);
  }

  public setUserImageUrl(userImageUrl: string) {
    localStorage.setItem(USER_IMAGE_URL, userImageUrl);
  }

  public getUserImageUrl(): string | null {
    return localStorage.getItem(USER_IMAGE_URL);
  }

  public removeUserImageUrl() {
    localStorage.removeItem(USER_IMAGE_URL);
  }

  carregaFormularioPLA(): Observable<any> {
    const pla = JSON.parse(localStorage.getItem(PLA) ?? '');
    return of(pla || []).pipe(delay(0));
  }

  async salvaFormularioPLA(formulario: any) {
    return window.localStorage.setItem(PLA, JSON.stringify(formulario));
  }

  async removeFormularioPLA() {
    return window.localStorage.removeItem(PLA);
  }

  public setCategoria(categoria: Categoria) {
    sessionStorage.setItem(CATEGORIA, JSON.stringify(categoria));
  }

  public getCategoria(): Categoria {
    return JSON.parse(`${sessionStorage.getItem(CATEGORIA)}`);
  }

  public removeCategoria() {
    sessionStorage.removeItem(CATEGORIA);
  }

  public setServicos(servicos: Servicos) {
    sessionStorage.setItem(CATEGORIA_SERVICO, JSON.stringify(servicos));
  }

  public getServicos() {
    return sessionStorage.getItem(CATEGORIA_SERVICO);
  }

  public removeServicos() {
    sessionStorage.removeItem(CATEGORIA_SERVICO);
  }

  public setFaltaAgua(value: any) {
    localStorage.setItem(FALTA_AGUA, JSON.stringify(value));
  }

  public getFaltaAgua() {
    const storedValue = localStorage.getItem(FALTA_AGUA);
    if (
      storedValue &&
      typeof storedValue === 'string' &&
      storedValue.trim() !== ''
    ) {
      return JSON.parse(storedValue);
    } else {
      return null; // Retorna null se o valor no localStorage for inválido ou vazio
    }
  }

  public removeFaltaAgua() {
    localStorage.removeItem(FALTA_AGUA);
    localStorage.removeItem(FORNECIMENTOS);
  }

  public setFornecimentos(fornecimentos: Fornecimento[]) {
    let jwt = localStorage.getItem("auth-token")
    sessionStorage.setItem(`${FORNECIMENTOS}-${jwt}`, JSON.stringify(fornecimentos));
  }
  public getFornecimentos() {
    const storedValue = localStorage.getItem(FORNECIMENTOS);
    if (
      storedValue &&
      typeof storedValue === 'string' &&
      storedValue.trim() !== ''
    ) {
      return JSON.parse(storedValue);
    } else {
      return null; // Retorna null se o valor no localStorage for inválido ou vazio
    }
  }

  public removeFornecimentos() {
    localStorage.removeItem(FORNECIMENTOS);
  }

  public async setCNPJRepresentante(
    representante: DadosRepresentante | null
  ): Promise<void> {
    localStorage.setItem(CNPJ_REPRESENTANTE, JSON.stringify(representante));
  }
  public getCNPJRepresentante(): DadosRepresentante | null {
    const storedValue = localStorage.getItem(CNPJ_REPRESENTANTE);
    if (
      storedValue &&
      typeof storedValue === 'string' &&
      storedValue.trim() !== ''
    ) {
      return JSON.parse(storedValue);
    } else {
      return null; // Retorna null se o valor no localStorage for inválido ou vazio
    }
  }
  public removeCNPJRepresentante() {
    localStorage.removeItem(CNPJ_REPRESENTANTE);
  }

  public setDesligamentoTitular(desligamento: any) {
    localStorage.setItem(DESLIGAMENTO_TITULAR, desligamento);
  }
  public getDesligamentoTitular() {
    return JSON.parse(localStorage.getItem(DESLIGAMENTO_TITULAR) ?? '');
  }

  public removeDesligamentoTitular() {
    localStorage.removeItem(DESLIGAMENTO_TITULAR);
  }

  public setDesligamentoResponsavel(nome: string, telefone: string) {
    const data = { nome, telefone };
    localStorage.setItem(DESLIGAMENTO_RESPONSAVEL, JSON.stringify(data));
  }

  public getDesligamentoResponsavel(): {
    nome: string;
    telefone: string;
  } | null {
    const data = localStorage.getItem(DESLIGAMENTO_RESPONSAVEL);
    return data ? JSON.parse(data) : null;
  }

  public removeDesligamentoResponsavel() {
    localStorage.removeItem(DESLIGAMENTO_RESPONSAVEL);
  }
  public setReqstDesligamento(reqst: RequestDesligamento) {
    localStorage.setItem(DESLIGAMENTO_RESQUEST, JSON.stringify(reqst));
  }

  public getReqstDesligamento(): RequestDesligamento {
    return JSON.parse(`${localStorage.getItem(DESLIGAMENTO_RESQUEST)}`);
  }

  public removeReqstDesligamento() {
    localStorage.removeItem(DESLIGAMENTO_RESQUEST);
  }

  public removeEnderecos() {
    localStorage.removeItem(ENDERECOS);
    localStorage.removeItem(FORNECIMENTOS);
  }
  public setEnderecos(value: any) {
    localStorage.setItem(ENDERECOS, JSON.stringify(value));
  }

  public getEnderecos() {
    const storedValue = localStorage.getItem(ENDERECOS);
    if (
      storedValue &&
      typeof storedValue === 'string' &&
      storedValue.trim() !== ''
    ) {
      return JSON.parse(storedValue);
    } else {
      return null; // Retorna null se o valor no localStorage for inválido ou vazio
    }
  }
}
