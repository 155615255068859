import {
  SimpleChanges,
  EventEmitter,
  ElementRef,
  Component,
  OnChanges,
  ViewChild,
  OnInit,
  Output,
  Input,
} from '@angular/core';
import { SelectModel } from './new-input-select.model';

@Component({
  selector: 'agv-new-input-select',
  template: `
    <div [id]="id" class="agv-new-input-select" [ngClass]="{ error: erro }">
      <label
        [attr.tabindex]="0"
        [for]="id + '__select'"
        class="agv-new-input-select__label"
        (click)="opened = !opened; buscaNaLista()"
        (keyup.space)="opened = !opened"
        (keyup.enter)="opened = !opened"
        >{{ label }}</label
      >
      <input
        #inputSelect
        [id]="id + '__select'"
        class="agv-new-input-select__input"
        type="text"
        name="select"
        [placeholder]="placeholder"
        (click)="opened = true;"
        maxlength="14"
        (ngModelChange)="buscaNaLista()"
        [(ngModel)]="busca"
      />
      <agv-icon
        role="button"
        [attr.tabindex]="0"
        class="agv-new-input-select__icone invert"
        [ngClass]="{ open: opened }"
        [name]="'arrow_black'"
        (click)="opened = !opened;"
        (keyup.space)="opened = !opened;"
        (keyup.enter)="opened = !opened;"
      ></agv-icon>
      <div
        *ngIf="opened"
        [id]="id + '__options'"
        class="agv-new-input-select__options"
      >
        <label
          *ngFor="let item of cloneFilter; let index = index; let last = last"
          data-testid="optins"
          [attr.tabindex]="0"
          [ngClass]="{ last: last }"
          [id]="id + '__options__option-' + index"
          class="agv-new-input-select__options__option"
          (click)="selectItem(item.id)"
          (keyup.space)="selectItem(item.id)"
          (keyup.enter)="selectItem(item.id)"
          [for]="id + '__select'"
        >
          {{ item.label }}
        </label>
      </div>
    </div>
  `,
  styles: [
    `
      .agv-new-input-select {
        border: 1px solid var(--btn-disabled);
        background: var(--color-white);
        flex-direction: column;
        padding: 13px 20px;
        border-radius: 4px;
        position: relative;
        max-height: 65px;
        display: flex;
        cursor: text;
        width: 100%;

        &__label {
          color: var(--color-black);
          line-height: 1.25rem;
          font-size: 0.75rem;
          font-weight: 500;
          cursor: text;
        }

        &__input {
          background: transparent !important;
          border: none;
          font-size: 0.875rem;
          color: var(--color-black);
          font-weight: 900;
          cursor: text;

          &:focus {
            outline: none;
            background: transparent !important;
          }

          &:active {
            outline: none;
            background: transparent !important;
          }

          &::placeholder {
            font-weight: 500;
            color: var(--color-black);
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            -webkit-background-clip: text;
            -webkit-text-fill-color: var(--color-black);
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px transparent;
          }
        }

        &__icone {
          transition: transform 0.5s;
          position: absolute;
          cursor: pointer;
          bottom: 25px;
          height: 16px;
          right: 15px;
          width: 16px;

          &.open {
            transform: rotate(180deg);
          }
        }

        &__options {
          border-bottom: 1px solid var(--btn-disabled);
          border-right: 1px solid var(--btn-disabled);
          border-left: 1px solid var(--btn-disabled);
          background-color: var(--color-white);
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
          flex-direction: column;
          position: absolute;
          max-height: 150px;
          overflow: auto;
          display: flex;
          height: auto;
          width: 100%;
          z-index: 1;
          top: 62px;
          left: 0;

          &::-webkit-scrollbar {
            width: 10px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: var(--color-white);
            border-radius: 10px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: var(--color-primary);
            border-radius: 10px;
          }

          &__option {
            display: flex;
            padding: 10px;
            cursor: pointer;
            border-bottom: 1px solid black;

            &.last {
              border-bottom: none;
            }

            &:hover {
              background-color: var(--color-th-installment);
            }
          }
        }

        &.error {
          border: 1px solid var(--color-failed);

          .agv-new-input-select {
            &__label,
            &__input {
              color: var(--color-failed);

              &::placeholder {
                color: var(--color-failed);
              }

              &:is(:-webkit-autofill, :autofill) {
                color: var(--color-failed);
              }
            }
          }
        }
      }
    `,
  ],
})
export class AgvInputSelectComponent implements OnInit, OnChanges {
  // Input - String
  @Input() placeholder: string = '';
  @Input() value: SelectModel;
  @Input() label: string = '';
  @Input() id: string = '';

  // Input - Boolean
  @Input() erro: boolean = false;

  // Input - Lista
  @Input() options: SelectModel[] = [];

  // Actions
  @Output() sendValue: EventEmitter<string> = new EventEmitter();
  @Output() sendObject: EventEmitter<SelectModel> = new EventEmitter();

  // Views
  @ViewChild('inputSelect') input: ElementRef | undefined;

  // String
  busca: string = '';

  // Boolean
  opened: boolean = false;

  // Lista
  cloneFilter: SelectModel[] = [];

  // Função executada ao iniciar o component
  ngOnInit() {
    this.busca = this.value.label
  }

  // Função executada ao identificar alteração nos inputs
  ngOnChanges(changes: SimpleChanges): void {
     if (changes['options']) {
      this.options = changes['options']?.currentValue;
      this.cloneFilter = this.options;
    }
  }

  // Função para identificar eventos no input
  onEvent() {
    this.buscaNaLista();
  }

  // Função para selecionar o item que o foi clicado
  selectItem(id: string) {
    this.cloneFilter = this.options;
    const obj = this.options.filter((x) => x.id === id);
    this.busca = obj[0].label;
    this.opened = false;
    this.sendObject.emit(obj[0]);
    this.sendValue.emit(obj[0].id);
  }

  // Função para efetuar filtro na lista
  buscaNaLista() {
    this.cloneFilter = this.options;
    if (this.busca) {
      this.cloneFilter = this.cloneFilter.filter((x) =>
        x.label
          .toLocaleUpperCase()
          .includes(`${this.busca.toLocaleUpperCase()}`)
      );
    }
  }
}
