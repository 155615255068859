import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2,
  Input,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ConsumoComparativoResponse } from 'src/app/core/models/iotModels';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { iotServices } from 'src/app/services/analiseIot.service';

Chart.register(...registerables, ChartDataLabels);

@Component({
  selector: 'chart-iot-diario',
  templateUrl: './chart-iot-diario.component.html',
  styleUrls: ['./chart-iot-diario.component.scss'],
})
export class ChartIotDiarioComponent implements OnInit {
  chart: any;
  selectedOption: string = '';
  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;

  private parentCallback!: (data: any) => void;
  @Input() consumoComparativoResponse: ConsumoComparativoResponse;
  @Input() selectedMonth: number;

  ngOnChanges(): void {
    if (this.selectedMonth) {
      this.updateChart();
    }
  }

  registerParentCallback(callback: (data: any) => void) {
    this.parentCallback = callback;
  }

  constructor(
    private renderer: Renderer2,
    private _storageToken: TokenStorageService,
    private iotServices: iotServices
  ) {}

  projecao: boolean = false;
  projecaoParcial: boolean = false;
  mesAnterior: boolean = false;
  anoAnterior: boolean = false;

  exibeConsumo: boolean = false;
  exibeParcial: boolean = false;
  exibeProjecao: boolean = false;
  exibeComparativo: boolean = false;

  ngOnInit(): void {
    this.createChart();
    this.requestParentChange();
  }

  requestParentChange() {
    if (this.parentCallback) {
      this.parentCallback(this.selectedOption);
    }
  }

  createChart(): void {
    const months = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];

    const teste = {
      id: 'testePlugion',
      afterDraw: (chart: any) => {
        const {
          chartArea: { left, top },
          scales: { y },
        } = chart;

        const outerContainer = document.getElementById('outerContainer');
        const labelsContainer = document.getElementById('labelsContainer');

        if (outerContainer && labelsContainer) {
          labelsContainer.innerHTML = ''; // Limpa os rótulos antigos

          const isMobile = window.innerWidth <= 768; // Verifica se a largura da tela é menor ou igual a 768px
          if (isMobile) {
            y.ticks.forEach((tick: any, index: any) => {
              const yPos = y.getPixelForTick(index);

              const div = document.createElement('div');
              div.className = 'mobileVolumeLabel';
              div.innerHTML = `<div>${tick.label}</div>`;
              div.style.position = 'absolute';
              div.style.left = `${left - 55}px`; // Ajuste conforme necessário
              div.style.top = `${yPos + top - 29}px`; // Ajuste conforme necessário
              div.style.fontSize = `12px`; // Ajuste conforme necessário
              div.style.inlineSize = `max-content`; // Ajuste conforme necessário
              labelsContainer.appendChild(div);
            });
          }
        }
      },
    };

    Chart.register(teste);

    const customLabelsPlugin = {
      id: 'customLabelsPlugin',
      afterDraw: (chart: any) => {
        const {
          ctx,
          chartArea: { bottom },
          scales: { x },
        } = chart;
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.font = '12px Arial';
        ctx.fillStyle = '#727373';
        const monthLabel = months[this.selectedMonth - 1];
        const isMobile = window.innerWidth <= 768; // Verifica se a largura da tela é menor ou igual a 768px

        x.ticks.forEach((tick: any, index: any) => {
          const xPos = x.getPixelForTick(index);
          ctx.fillText(monthLabel, xPos, bottom + 26);

          // Adicionar borda ao redor de cada dia apenas no mobile
          if (isMobile) {
            ctx.strokeStyle = '#D9D9D9'; // Cor da borda
            ctx.lineWidth = 1;
            ctx.strokeRect(xPos - 15, bottom + 10, 30, 30); // Ajustar conforme necessário
          }
        });

        ctx.restore();
      },

    };


    const canvas = document.getElementById(
      'chartIotDiario'
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: Array.from({ length: 31 }, (_, i) => `${i + 1}`),
        datasets: [],
      },
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 10,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
            grid: {
              display: false,
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
            },
            ticks: {
              stepSize: 0.5,
              callback: function (value, index, values) {
                return value + ' m³';
              },
            },
            beginAtZero: true,
            min: 0,
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            mode: 'nearest',
            intersect: false,
            backgroundColor: '#ffffff', // Fundo branco
            titleColor: '#000000', // Texto do título preto
            bodyColor: '#000000', // Texto do corpo preto
            footerColor: '#000000', // Texto do rodapé preto
            borderColor: '#cccccc', // Cor da borda
            borderWidth: 1, // Largura da borda
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += context.raw + ' m³';
                return label;
              },
            },
          },
        },
      },
      plugins: [customLabelsPlugin],
    });

    if (canvas.parentNode) {
      (canvas.parentNode as HTMLElement).style.height = '420px';
    }

    this.addHtmlElementsBelowChart();
  }

  addHtmlElementsBelowChart(): void {
    const container = this.chartContainer.nativeElement;
    const labels = Array.from({ length: 31 }, (_, i) => `${i + 1}`);
    labels.forEach((label) => {
      const div = document.createElement('div');
      div.className = 'custom-label';
      div.innerHTML = `<div>${label}</div><div>dia</div>`;
      container.appendChild(div);
    });
  }

  updateChart(): void {
    if (!this.consumoComparativoResponse) {
      console.error('consumoComparativoResponse is undefined');
      return;
    }

    if (!this.consumoComparativoResponse.consumo) {
      console.error('consumoComparativoResponse.consumo is undefined');
      return;
    }

    const consumoData = this.consumoComparativoResponse.consumo.map(
      (item) => item.consumo
    );
    const comparativoData = this.consumoComparativoResponse.consumo.map(
      (item) => item.comparativo
    );
    const previsaoData = this.consumoComparativoResponse.consumo.map(
      (item) => item.previsao
    );

    let datasets = [];

    this.exibeComparativo = true;
    if (this.selectedMonth < 8) {
      this.exibeConsumo = true;
      this.exibeParcial = false;
      this.exibeProjecao = false;
      datasets.push({
        label: 'Consumo',
        data: consumoData,
        borderColor: '#00A5E4',
        backgroundColor: '#00A5E4',
        fill: false,
        tension: 0.3,
        pointRadius: 0,
      });
    } else if (this.selectedMonth === 8) {
      this.exibeConsumo = false;
      this.exibeParcial = true;
      this.exibeProjecao = true;
      const splitIndex = Math.floor(consumoData.length / 2);
      let dataWithColor: any = [];
      consumoData.forEach((value, index) => {
        dataWithColor.push(
          index < splitIndex
            ? this.consumoComparativoResponse.consumo[index].consumo
            : this.consumoComparativoResponse.consumo[index].previsao
        );
      });
      datasets.push({
        label: 'Consumo',
        data: dataWithColor,
        pointBackgroundColor: '#fff',
        pointBorderColor: '#D86F23',
        pointBorderWidth: 3,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 3,
        borderColor: (ctx: any) => {
          const index = ctx.dataIndex;
          return index < splitIndex ? '#00A5E4' : '#D86F23';
        },
        backgroundColor: (ctx: any) => {
          const index = ctx.dataIndex;
          return index < splitIndex ? '#00A5E4' : '#D86F23';
        },
        fill: false,
        tension: 0.3,
        pointRadius: (context: any) => {
          const index = context.dataIndex + 1;
          return index === splitIndex ? 7 : 0;
        },
        segment: {
          borderColor: (ctx: any) => {
            const { p0, p1, p1DataIndex } = ctx;
            return p1DataIndex < splitIndex ? '#00A5E4' : '#D86F23';
          },
          backgroundColor: (ctx: any) => {
            const { p0, p1, p1DataIndex } = ctx;
            return p1DataIndex < splitIndex ? '#00A5E4' : '#D86F23';
          },
        },
      });
    } else if (this.selectedMonth > 8) {
      this.exibeConsumo = false;
      this.exibeParcial = false;
      this.exibeProjecao = true;

      datasets.push({
        label: 'Previsão',
        data: previsaoData,
        borderColor: '#D86F23',
        backgroundColor: '#D86F23',
        fill: false,
        tension: 0.3,
        pointRadius: 0,
      });
    }

    if (this.consumoComparativoResponse.variacaoTotal !== undefined) {
      if (this.consumoComparativoResponse.variacaoTotal > 0) {
        // Handle positive variation
      } else if (this.consumoComparativoResponse.variacaoTotal < 0) {
        // Handle negative variation
      } else if (this.consumoComparativoResponse.variacaoTotal === 0) {
        // Handle zero variation
      }
    }

    if (
      this.consumoComparativoResponse.consumoComparativo != null &&
      this.selectedOption !== ''
    ) {
      let borderColor = '#C059FF';
      let backgroundColor = '#C059FF';
      if (this.selectedOption === 'ano') {
        borderColor = '#23D8B7';
        backgroundColor = '#23D8B7';
      }
      datasets.push({
        label: 'Comparativo',
        data: comparativoData,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        fill: false,
        tension: 0.3,
        pointRadius: 0,
        borderDash: [5, 4],
      });
    }

    if (this.chart) {
      this.chart.data.datasets = datasets;
      this.chart.update();
    } else {
      this.createChart();

      console.error('Chart instance is not defined');
    }
  }
}
