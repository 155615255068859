import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'agv-button-edit',
  template: `<button
    [id]="id + '__agv-btn-edit'"
    class="agv-btn-edit"
    (click)="action()"
    (keypress.space)="action()"
    (keypress.enter)="action()"
  >
    <agv-icon
      [name]="'button-edit'"
      [id]="id + '__agv-btn-edit__btn'"
      class="agv-btn-edit__btn"
    ></agv-icon>
    <span [id]="id + '__agv-btn-edit__text'" class="agv-btn-edit__text"
      >Editar</span
    >
  </button>`,
  styles: [
    `
      .agv-btn-edit {
        width: 50px;
        height: 46px;
        border: 1px solid var(--color-border-custom-button);
        border-radius: 4px;
        background: var(--color-white);

        &__btn {
          display: flex;
        }

        &__text {
          display: flex;
          width: 100%;
          justify-content: center;
          font-size: 0.625rem;
        }
      }
    `,
  ],
})
export class AgvButtonEditComponent {
  // input String
  @Input() id: string;

  // Action
  @Output() sendAction = new EventEmitter<any>();

  // Função para emitir a ação do botão
  action() {
    this.sendAction.emit();
  }
}
