import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { OutrosDetalhes } from 'src/app/core/models/pagamentoFaturaItens';
import { PagamentoFaturaService } from 'src/app/shared/components/pagamento-fatura/pagamento-fatura.service';
import { Validator } from 'src/app/shared/utils/validator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IFaturaEmailState } from 'src/app/core/store/email/fatura-email.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { FaturaEmail } from 'src/app/core/models/service/faturaEmail';
import {
  Subject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  finalize,
  map,
  shareReplay,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import {
  carregaParametro,
  sucessoCarregaParametro,
} from 'src/app/core/store/parametros/parametros.actions';
import { IParametrosState } from 'src/app/core/store/parametros/parametros.reducer';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { Router } from '@angular/router';
import { ClienteService } from 'src/app/shared/observables/cliente.service';
import { CNPJService } from 'src/app/shared/observables/cnpj.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogPesquisaSastifacaoComponent } from '../../dialog-pesquisa-sastifacao/dialog-pesquisa-sastifacao.component';
import { EmailService } from 'src/app/services/email.service';
import { Regex } from 'src/app/shared/utils/regex';
import { FornecimentoService } from 'src/app/shared/observables/fornecimento.service';

interface errorCasesEmail {
  invalid: boolean;
  type: string;
  message: string;
}
@Component({
  selector: 'app-por-email',
  templateUrl: './por-email.component.html',
  styleUrls: ['./por-email.component.scss'],
})
export class PorEmailComponent implements OnInit {
  // Actions
  @Output() envioPorEmail: EventEmitter<any> = new EventEmitter();

  // Outros
  @ViewChild('checkTermo') checkTermoElement: ElementRef;

  // Objects
  outros: OutrosDetalhes = {} as OutrosDetalhes;
  erro: errorCasesEmail = {} as errorCasesEmail;

  // Boolean
  errorInputConfirmaEmail: boolean;
  erroCadastroEmail: boolean;
  errorInputEmail: boolean;
  showPopUpTermo: boolean;
  isEncerramento: boolean;
  cadastrado: boolean;
  isMobile: boolean;
  aceite: boolean;
  carregando: boolean = false;
  fornecimentoCodigo: string = '';

  // String
  confirmaEmail: string;
  tituloPopUp: string;
  textoPopUp: string;
  nProtocolo: string;
  email: string;

  emailForm: FormGroup = new FormBuilder().group({
    email: ['', [Validators.required, Validators.email]],
    emailConfirmar: ['', [Validators.required]],
  });

  private unsubscribe$: Subject<void> = new Subject<void>();
  codigoCliente: any;
  cnpj: any;

  constructor(
    private readonly pagamentoFaturaService: PagamentoFaturaService,
    private storeP: Store<{ parametro: IParametrosState }>,
    private clienteObs: ClienteService,
    private emailService: EmailService,
    private cnpjObs: CNPJService,
    private updates$: Actions,
    private dialog: MatDialog,
    private router: Router,
    private fornecimentoObs: FornecimentoService
  ) {}

  ngOnInit(): void {
    this.outros =
      this.pagamentoFaturaService.getInfoPagamentoFaturaItens().outrosDetalhes;

    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
    combineLatest([
      this.cnpjObs.getRepresentante(),
      this.fornecimentoObs.currentFornecimento,
    ])
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([cnpj, fornecimento]) => ({ cnpj, fornecimento }))
      )
      .subscribe(({ cnpj, fornecimento }) => {
        this.cnpj = cnpj;
        this.fornecimentoCodigo = fornecimento.codigo;
      });

    window.onresize = () => (this.isMobile = window.innerWidth <= 768);
    this.clienteObs.currentCliente.subscribe((cliente) => {
      this.codigoCliente = cliente;
    });

    this.isEncerramento = this.router.url.includes('encerramento-contratual');
  }

  cadastrar() {
    if (!this.aceite) {
      this.erro = {
        invalid: true,
        type: 'terms not accepted',
        message: 'É obrigatório o aceite de termos e serviços.',
      };
    } else {
      this.erro = {
        invalid: false,
        type: '',
        message: '',
      };
      if (this.fornecimentoCodigo) {
        this.alterarEnvioEmail(
          this.email,
          this.confirmaEmail,
          this.fornecimentoCodigo
        );
      }
      this.envioPorEmail.emit();
    }
  }

  alterarEnvioEmail(
    email: string,
    confirmaEmail: string,
    fornecimento: string
  ) {
    this.carregando = true;
    const isRepLegal =
      this.router.url.includes('negocios/empresa') ||
      localStorage.getItem('FluxoPJ');

    let faturaEmail: FaturaEmail = new FaturaEmail(
      email,
      confirmaEmail,
      fornecimento,
      this.codigoCliente?.codigo,
      isRepLegal ? Regex.removeMaskCnpj(this.cnpj?.cnpj) : ''
    );

    this.emailService
      .enviaEmail(faturaEmail)
      .pipe(
        takeUntil(this.unsubscribe$),
        take(1),
        distinctUntilChanged(),
        tap(() => {
          this.cadastrado = true;
          this.erroCadastroEmail = false;
        }),
        catchError((error: any) => {
          this.erroCadastroEmail = true;
          this.cadastrado = false;
          console.error('Erro ao enviar e-mail:', error);
          return throwError(() => error);
        }),
        finalize(() => {
          this.carregando = false;
        })
      )
      .subscribe({
        next: (data: any) => {
          this.nProtocolo = data.protocolo;
          this.dialog.open(DialogPesquisaSastifacaoComponent, {
            width: '600px',
            maxWidth: '90vw',
            panelClass: 'dialog-pesquisa-satifacao-container',
            data: this.nProtocolo,
            disableClose: true,
          });
        },
        error: () => {
          this.erroCadastroEmail = true;
          this.cadastrado = false;
        },
      });
  }

  aceitaTermo(evento: any) {
    if (Validator.validaEventKeyPressClick(evento)) {
      this.aceite = !this.aceite;
      setTimeout(() => {
        //Carrega o focus no título
        this.checkTermoElement?.nativeElement.focus();
      }, 0);
    }
  }

  verificaErro(): void {
    if (this.emailForm.controls['email'].hasError('required')) {
      this.erro = {
        invalid: true,
        type: 'required',
        message: 'É necessário digitar um e-mail para poder prosseguir.',
      };
      return;
    }
    if (this.emailForm.controls['email'].hasError('email')) {
      this.erro = {
        invalid: true,
        type: 'format',
        message: 'E-mail inválido. Digite um e-mail válido para prosseguir.',
      };
      return;
    }
    if (
      this.emailForm.controls['email'].value !=
      this.emailForm.controls['emailConfirmar'].value
    ) {
      this.erro = {
        invalid: true,
        type: 'not equals',
        message: 'Os e-mails precisam ser iguais para prosseguir.',
      };
      return;
    } else {
      this.email = this.emailForm.controls['email'].value;
      this.confirmaEmail = this.emailForm.controls['emailConfirmar'].value;
      this.erro = {
        invalid: false,
        type: '',
        message: '',
      };
    }
  }

  abrirPopUpTermo() {
    if (!this.showPopUpTermo) {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
      let codigo: string = '125';
      this.storeP.dispatch(carregaParametro({ codigo }));
      this.updates$
        .pipe(ofType(sucessoCarregaParametro), takeUntil(this.unsubscribe$))
        .subscribe({
          next: (action) => {
            this.tituloPopUp = `TERMO DE ACEITE`;
            this.textoPopUp = `${action.valor.valor_string}`;
            this.showPopUpTermo = !this.showPopUpTermo;
          },
          error: (erro) => {
            this.tituloPopUp = `TERMO DE ACEITE`;
            this.textoPopUp = `Não foi possível carregar o termo de aceite, por favor tente novamente mais tarde!`;
            console.error(erro);
          },
        });
    } else {
      this.showPopUpTermo = !this.showPopUpTermo;
    }
  }
}
