import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
  Inject,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { ClienteCompleto } from 'src/app/core/models/service/cadastroCliente';
import { MenuServicoService } from '../menu-servicos/menu-servico.service';
import { ClienteService } from '../../observables/cliente.service';
import { ItensMenu } from 'src/app/core/models/menuServicoItens';
import { ThemeService } from '../../observables/theme.service';
import { UserService } from '../../../services/user.service';
import { CNPJService } from '../../observables/cnpj.service';
import { environment } from 'src/environments/environment';
import { IResponseAvatar } from '../../models/user.model';
import { TabService } from '../tab/tab.service';
import { Regex } from '../../utils/regex';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { deletaEmpresaCNPJ } from 'src/app/core/store/empresa-cnpj/empresa-cnpj.actions';
import { MatDialog } from '@angular/material/dialog';
import { DialogNovaAgvAvisoCNPJComponent } from '../dialog-nova-agv-aviso-cnpj/dialog-nova-agv-aviso-cnpj.component';

import { Notifications } from 'src/app/core/models/notification';

@Component({
  selector: 'agv-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnChanges, AfterViewInit {
  // External value
  @Input() isSidenavOpen: boolean = false;
  estaLogado: boolean = false;
  @Input() avatar: IResponseAvatar;
  cliente: ClienteCompleto | undefined;

  // Event external
  @Output() sidenavToggle = new EventEmitter();

  // Element Reference
  @ViewChild('buttonToggle') buttonToggle: ElementRef;
  @ViewChild('userMenu') userMenuElement: ElementRef;

  // String
  editUserIcon: string | undefined =
    '../../../../assets/imgs/svg/account_circle.svg';
  userIcon: string = '../../../../assets/imgs/svg/icon-edit-photo.svg';
  icon_notification: string = '../../../../assets/imgs/svg/icon_notification_2.svg';
  currentTheme: string;
  termoPesquisa: string = '';
  url = environment.urlAGV;
  token: string | null;

  // Boolean
  isMenuUserOpen: boolean = false;
  showEditaFoto: boolean = false;
  lightTheme: boolean = true;
  invalid: boolean = false;
  isHomolog = environment.env == 'hml';
  isQuality = environment.env == 'qua';

  // Number
  fontSize: number = 0;

  // List
  items: MenuItem[] = [{ label: 'Para Você ' }, { label: 'Para Negócios' }];
  activeItem: MenuItem = this.items[0];
  links = ['Para você', 'Para Negócios', 'Notícias'];
  imagens: UserService[] = [];
  servicos: ItensMenu[] = [];
  activeLink = this.links[0];
  servicosFiltrados: any[];

  private _notificationsResponse: Notifications = { all: [], unreadCount: 0 };

  get notificationsResponse(): Notifications {
    return this._notificationsResponse;
  }

  set notificationsResponse(value: Notifications) {
    this._notificationsResponse = value;
  }

  // Object
  menuServico: MenuServicoService = new MenuServicoService();

  private unsubscribe$: Subject<void> = new Subject<void>();

  // Contrutor
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _serviceToken: TokenStorageService,
    private UserService: UserService,
    private _serviceTab: TabService,
    private cliObs: ClienteService,
    private elementRef: ElementRef,
    private cnpjObs: CNPJService,
    private theme: ThemeService,
    private tabObs: TabService,
    private route: Router,
    private store: Store,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    if (this.checkToken()) {
      this.cliObs
        .getCliente()
        .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
        .subscribe((cliente) => {
          if (cliente?.codigo) {
            this.cliente = cliente;
            this.cliente.nome = this.cliente?.nome.toLowerCase();
            this.cliente.sobrenome = this.cliente?.sobrenome.toLowerCase();
            this.estaLogado = cliente != undefined && cliente.nome !== '';
          }
        });
    }
  }

  // Captura alterações em variáveis
  ngOnChanges(): void {
    if (this.avatar) {
      this.editUserIcon = this.avatar?.urlImg;
      this.userIcon = this.editUserIcon;
    }
  }

  // Executa no inicia da aplicação
  ngOnInit(): void {
    this.cliObs.currentCliente.subscribe((cliente) => {
      this.cliente = cliente;
      this.estaLogado = cliente != undefined && cliente.nome !== '';
    });
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
    this.servicos = this.menuServico.getInfoMenuItens().items;
    if (
      this.route.url.includes('negocios') ||
      localStorage.getItem('FluxoPJ')
    ) {
      localStorage.setItem('FluxoPJ', 'true');
      this.activeLink = this.links[1];
    } else {
      localStorage.removeItem('FluxoPJ');
      this.activeLink = this.links[0];
    }
    localStorage.removeItem('codFornecimento');
    this.validateTabActive();
    this.getNotificacoesNaoLidas();
    this.readNotification();
  }

  // Verifica se está no tema escuro
  get isDarkMode(): boolean {
    return this.currentTheme === 'theme-dark';
  }

  // Pega o nome do cliente logado
  get name(): string {
    return `${this.cliente?.nome} ${this.cliente?.sobrenome}`.toLowerCase();
  }

  get namelow(): string {
    return `${this.cliente?.nome}`.toLowerCase();
  }

  // Abre o menu na versão mobile
  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  // Valida qual aba a tela está
  public validateTabActive() {
    if (this.route.url.includes('sabesp-informa')) {
      this.activeLink = this.links[2];
    } else if (this.route.url.includes('negocios/empresa')) {
      this.activeLink = this.links[1];
    } else {
      this.activeLink = this.links[0];
    }
  }

  // Filtra serviços na pesquisa versão desktop
  filtrarServicos() {
    this.servicosFiltrados = this.servicos.filter((servico) =>
      Regex.removerAcentos(servico.nome.toLowerCase()).includes(
        Regex.removerAcentos(this.termoPesquisa.toLowerCase())
      )
    );
  }

  // botão de logout
  public signOut() {
    this._serviceToken.signOut();
    this.cliObs.changeCliente(undefined);
    localStorage.removeItem('userImageUrl');
    localStorage.removeItem('pageReturn')
    this.route.navigate(['/']);
  }

  // Redireciona o ususário para a tela de representante
  public clickTabs(link: string) {
    if (link === this.links[0]) {
      this.route.navigate(['/fatura-completa']);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.removeFluxoCNPJ();
    } else if (link === this.links[1]) {
      if (
        this._serviceToken.checkToken() &&
        this._serviceToken.getTipoUsuario() === 'PF,PJ'
      ) {
        this.route.navigate(['/negocios/empresa']);
        this.tabObs.changeTab(1);
        localStorage.setItem('FluxoPJ', 'true');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        this.dialog.open(DialogNovaAgvAvisoCNPJComponent, {
          maxWidth: '500px',
          panelClass: 'dialog-aviso-cnpj',
        });
        this.tabObs.changeTab(0);
        link = this.links[0]
        this.route.navigate(['/fatura-completa']);
      }

    } else {
      const element = document.getElementById('noticia');
      this.tabObs.changeTab(3);
      if (element) {
        const navbarHeight =
          document.getElementById('navegacao-secoes')?.offsetHeight || 0;
        const offset = element.offsetTop - navbarHeight + 36;
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
    }
    this.activeLink = link;
  }

  // Abre o menu de usuário
  openUserMenu() {
    window.scroll(0, 0);
    switch (true) {
      case !this.estaLogado:
        this.route.navigateByUrl('');
        this._serviceTab.changeTab(1);
        break;
      default:
        this.isMenuUserOpen = !this.isMenuUserOpen;
        const ariaLabel = this.isMenuUserOpen
          ? 'Menu do usuário aberto'
          : 'menu do usuário fechado';
        const elemento = document.getElementById('texto-logar');
        if (elemento !== null) {
          elemento.setAttribute('aria-label', ariaLabel);
        }
        break;
    }
  }

  // Abre o menu de usuário pelo teclado
  openUserMenuKeyPress(event: any) {
    var key = event.which || event.keyCode;
    if (key == 13 || key == 32) {
      this.openUserMenu();
    }
  }

  // Salva a imagem do usuário
  enviaFoto(event: any) {
    const pictureFile = event.target.files[0] as File;

    if (pictureFile) {
      const allowedTypes = ['image/png', 'image/jpeg'];
      const maxSize = 6 * 1024 * 1024; // 6 MB

      if (!allowedTypes.includes(pictureFile.type)) {
        this.openDialogError(
          'Formato de arquivo não suportado. Escolha um arquivo .jpg, .jpeg ou .png e tente novamente.'
        );
      } else if (pictureFile.size > maxSize) {
        this.openDialogError(
          'Arquivo excede o tamanho máximo de 6 MB. Escolha um arquivo menor e tente novamente.'
        );
      } else {
        this.invalid = false;
        this.UserService.atualizarFotoPerfilUsuario(pictureFile).subscribe({
          next: (data) => {
            this.imagens = this.imagens.concat(data);
            this.editUserIcon = data.urlImg;
            this.userIcon = data.urlImg;
            localStorage.setItem('userImageUrl', `${this.editUserIcon}`);
            this.showEditaFoto = true;
          },
          error: (error) => {
            console.error('Erro ao fazer upload da foto.', error);
          },
        });
      }
    }
  }

  // Apresenta as mensagens de erro
  openDialogError(message: string) {}

  // Mosta icone de alteração de imagem de perfil
  hoverIconOut() {
    setTimeout(() => {
      this.showEditaFoto = false;
    }, 500);
  }

  // Fecha menu ao clicar fora
  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    event.stopPropagation();
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.isMenuUserOpen = false;
    }
  }

  // Fecha menu ao redimenciona a tela
  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    const w = event.target as Window;
    const width = w.innerWidth;
    if (width <= 912) this.isMenuUserOpen = false;
  }

  // Redireciona para a alteração de senha
  redirectAlteraSenha() {
    this.route.navigateByUrl('/alterar-senha');
  }
  redirectMinhasSolicitacoes() {
    this.route.navigateByUrl('/minhas-solicitacoes');
  }

  redirectrNotifications() {
    this.route.navigateByUrl('/notificacoes');
  }

  // Cria o texto de leitura para o aria-label
  createAriaLabel(step: any) {
    if (step === 1) {
      if (this.estaLogado) {
        return `Olá, ${this.cliente?.nome}. Clique aqui para acessar o menu do usuário!`;
      } else {
        return `Link para logar na Agência Virtual`;
      }
    }
    return ``;
  }

  showIconAvatar(): boolean | undefined {
    return this.editUserIcon?.includes('account_circle');
  }

  redirecionaServico(servico: ItensMenu): void {
    servico.interno
      ? this.route.navigateByUrl(servico.link)
      : this.redirecionaLinkExterno(servico.link);
  }

  redirecionaLinkExterno(link: string): void {
    if (this.checkToken())
      window.open(this.url + `?token=${this.token}&servico=${link}`, '_blank');
    else window.open(`${this.url}${link}`);
  }

  checkToken(): boolean {
    if (this._serviceToken.checkToken()) {
      this.token = this._serviceToken.getToken();
      return true;
    } else {
      return false;
    }
  }

  removeFluxoCNPJ() {
    localStorage.removeItem('FluxoPJ');
    this.tabObs.changeTab(0);
    this.cnpjObs.changeCNPJ(undefined);
    this._serviceToken.removeCNPJRepresentante();
    this.store.dispatch(deletaEmpresaCNPJ());
  }

  redirectMinhaConta() {
    this.route.navigateByUrl('/minha-conta');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dropdownOpen = false; // Adicione esta linha para declarar a variável

  toggleDropdown(): void { // Adicione este método
    this.dropdownOpen = !this.dropdownOpen;
  }

  redirectToAllNotifications(): void {
    this.router.navigate(['/notificacoes']); // Navegue para a rota de notificações
  }

  getNotificacoesNaoLidas(): void {
    let token = this._serviceToken.getToken();
    let fornecimentoData = localStorage.getItem('fornecimento');
    let fornecimento;

    if (fornecimentoData) {
      fornecimento = JSON.parse(fornecimentoData);
    }

    if (fornecimento && fornecimento.codigo) {
      this.UserService.getNotificacoesNaoLidas(token, fornecimento.codigo).subscribe({
        next: (data: any) => {
          this.notificationsResponse = data;
        },
        error: (err) => {
          console.error('Erro ao obter notificações:', err);
        },
        complete: () => {
        }
      });
    } else {
      console.error('Fornecimento não encontrado ou código inválido.');
    }
  }

  readNotification(): void {
    let token = this._serviceToken.getToken();
    let fornecimentoData = localStorage.getItem('fornecimento');
    let fornecimento: any;

    if (fornecimentoData) {
      fornecimento = JSON.parse(fornecimentoData);
    }

    if (fornecimento && fornecimento.codigo) {
      this.notificationsResponse.all.map(notification => {
        this.UserService.readNotification(token, fornecimento.codigo, notification.id).subscribe({
          next: (data: any) => {
            // Atualiza a notificação específica como lida, se necessário
            notification.isNew = false;
          },
          error: (err) => {
            console.error(`Erro ao marcar notificação ${notification.id} como lida:`, err);
          }
        });
      });
      this.updateUnreadCount(); // Atualiza a contagem de não lidas após marcar todas como lidas
  }}

  updateUnreadCount(): void {
    this.notificationsResponse.unreadCount = this.notificationsResponse.all.filter(notification => notification.isNew).length;
  }
}
