import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'agv-button-exc',
  template: `<button
    [id]="id + '__agv-btn-exc'"
    class="agv-btn-exc"
    (click)="action()"
    (keypress.space)="action()"
    (keypress.enter)="action()"
  >
    <agv-icon
      [name]="'button-delete'"
      [id]="id + '__agv-btn-exc__btn'"
      class="agv-btn-exc__btn"
    ></agv-icon>
    <span [id]="id + '__agv-btn-exc__text'" class="agv-btn-exc__text"
      >Remover</span
    >
  </button>`,
  styles: [
    `
      .agv-btn-exc {
        width: 50px;
        height: 46px;
        border: 1px solid var(--color-border-custom-button);
        border-radius: 4px;
        background: var(--color-white);

        &__btn {
          display: flex;
        }

        &__text {
          display: flex;
          width: 100%;
          justify-content: center;
          font-size: 0.625rem;
        }
      }
    `,
  ],
})
export class AgvButtonExcComponent {
  // Input String
  @Input() id: string;

  // Actions
  @Output() sendAction = new EventEmitter<any>();

  // Função para emitir a ação do botão
  action() {
    this.sendAction.emit();
  }
}
