import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'agv-slide-toggle',
  template: `
    <div role="group" class="slide-toggle" tabindex="0">
      <label [for]="id+'__slideToggle'" [innerHTML]="textLabel" *ngIf="labelLeft"> </label>
      <input
        role="checkbox"
        type="checkbox"
        name="slideToggle"
        [id]="id+'__slideToggle'"
        (change)="changeValor()"
        [checked]="check"
        [attr.aria-label]="textAriaLabel"
      />
      <label [for]="id+'__slideToggle'" [innerHTML]="textLabel" *ngIf="!labelLeft"> </label>
    </div>
  `,
  styles: [
    `
      .slide-toggle {
        align-items: start;
        display: flex;
        gap: 10px;

        &.disabled-click {
          input[type='checkbox'],
          label {
            cursor: not-allowed;
          }
        }

        input[type='checkbox'] {
          position: relative;
          background-color: var(--color-medium-gray);
          width: 28px;
          min-width: 28px;
          height: 16px;
          border-radius: 50px;
          appearance: none;
          -webkit-appearance: none;
          outline: none;
          transition: 0.3s;
          cursor: pointer;

          &::before {
            content: '';
            position: absolute;
            height: 11px;
            width: 11px;
            background-color: var(--color-white);
            top: 50%;
            left: 1px;
            border-radius: 50%;
            transition: 0.3s;
            transform: translate(0, -50%);
          }

          &:checked {
            background-color: var(--color-primary);

            &::before {
              left: 14px;
            }
          }
        }

        label {
          cursor: pointer;
          font-size: 0.75rem;
          max-width: 445px;
          text-align: left;
        }
      }
    `,
  ],
})
export class SlideToggleComponent implements OnInit, OnChanges {

  // Input String
  @Input() textAriaLabel: string = '';
  @Input() textLabel: string = '';
  @Input() id: string = '';

  // Input Boolean
  @Input() labelLeft: boolean = false;
  @Input() check: boolean;

  // Actions
  @Output() valorCheck = new EventEmitter<any>();

  constructor() {}
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["check"].currentValue !== changes["check"].previousValue) {
      this.check = changes["check"].currentValue;
    }
  }

  changeValor() {
    this.check = !this.check;
    this.valorCheck.emit(this.check);
  }
}
