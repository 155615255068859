import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'agv-dialog',

  template: `<div [id]="id + '__fade-modal'" class="fade-modal">
    <div [id]="id + '__fade-modal__content'" class="fade-modal__content">
      <agv-icon
        [id]="id + 'fade-modal__content__close'"
        class="fade-modal__content__close"
        tabindex="0"
        [name]="'icone_x'"
        (click)="fechar()"
        ></agv-icon>
        <agv-icon
        [id]="id + 'fade-modal__content__icon'"
        class="fade-modal__content__icon"
        tabindex="0"
        [name]="img"
      ></agv-icon>
      <p
        [id]="id + '__fade-modal__content__title'"
        class="fade-modal__content__title"
        tabindex="0"
      >
        {{ title }}
      </p>
      <p
        [id]="id + '__fade-modal__content__mensagem'"
        class="fade-modal__content__mensagem"
        tabindex="0"
        [innerHTML]="mensagem"
      >
      </p>
      <div
        [id]="id + '__fade-modal__content__btns'"
        class="fade-modal__content__btns"
      >
        <agv-button
          [id]="id + '__fade-modal__content__btns__continuar'"
          [habilita]="true"
          [text]="textBtn01"
          [type]="'2'"
          [loading]="false"
          (sendAction)="cancelar()"
          tabindex="0"
        ></agv-button>
        <agv-button
          [id]="id + '__fade-modal__content__btns__cancelar'"
          [habilita]="true"
          [text]="textBtn02"
          [type]="'1'"
          [loading]="false"
          (sendAction)="confirmar()"
          tabindex="0"
        ></agv-button>
      </div>
    </div>
  </div>`,
  styles: [
    `
      .fade-modal {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999999;
        top: 0;
        left: 0;

        &__content {
          width: 100%;
          max-width: 475px;
          border-radius: 4px;
          padding: 20px;
          background: var(--color-white);
          min-height: 350px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          text-align: center;
          position: relative;

          &__icon {
            margin-bottom: 10px;
          }

          &__close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
          }

          &__title {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.25rem;
          }

          &__mensagem {
            margin-bottom: 10px;
          }

          &__btns {
            display: flex;
            justify-content: space-between;

            agv-button {
                width: 45%;
                font-size: 1rem;
            }
          }
        }
      }
    `,
  ],
})
export class AgvDialogComponent implements OnInit {
  // Actions
  @Output() sendConfirm = new EventEmitter<any>();
  @Output() sendCancel = new EventEmitter<any>();
  @Output() sendClose = new EventEmitter<any>();

  // String
  @Input() mensagem: string = 'Conteúdo da Mensagem';
  @Input() title: string = 'Títúlo da Mensagem';
  @Input() textBtn02: string = 'Continuar';
  @Input() textBtn01: string = 'Cancelar';
  @Input() img: string = 'icon_atencao';
  @Input() id: string = '';

  constructor() {}

  ngOnInit(): void {}

  confirmar() {
    this.sendConfirm.emit();
  }

  cancelar() {
    this.sendCancel.emit();
  }

  fechar() {
    this.sendClose.emit();
  }
}
