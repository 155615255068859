import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'agv-button',
  template: `
    <button
      (click)="action($event)"
      (keypress)="action($event)"
      [id]="id + '__action'"
      [ngClass]="{
        'button-continuar': type === '1',
        'button-cancelar': type === '2'
      }"
      [disabled]="!habilita"
      [tabindex]="habilita ? '0' : '-1'"
    >
      <span *ngIf="!loading">{{ text }}</span>
      <agv-icon *ngIf="loading" class="loading" [name]="'loading'"></agv-icon>
    </button>
  `,
  styles: [
    `
      .button-cancelar {
        height: 64px;
        border-radius: 4px;
        font-size: 1rem;
        color: var(--color-primary);
        border: 2px solid var(--color-primary);
        background-color: var(--color-always-white);
        width: 100%;
        max-width: 474px;

        &:disabled {
          border: 2px solid var(--color-medium-gray);
          color: var(--color-medium-gray);
          cursor: not-allowed;
        }
      }

      .button-continuar {
        display: flex;
        width: 100%;
        max-width: 474px;
        padding: 22px 31.14px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        height: 64px;
        background-color: var(--color-primary);
        color: var(--color-always-white);
        border: 2px var(--color-primary);
        cursor: pointer;

        &:disabled {
          border: 2px solid var(--btn-disabled);
          color: var(--color-always-grey);
          background: var(--btn-disabled);
          cursor: not-allowed;
        }

        .loading {
          animation: spin 2s linear infinite;
        }
      }
    `,
  ],
})
export class AgvButtonComponent {
  // Input String
  @Input() text: string = 'Continuar';
  @Input() type: string = '1';
  @Input() id: string = '';
  
  // Input Boolean
  @Input() habilita: boolean = false;
  @Input() loading: boolean = false;

  // Actions
  @Output() sendAction = new EventEmitter<any>();

  constructor() {}

  action(e: any) {
    if (!this.loading) {
      this.sendAction.emit();
    }
  }
}
