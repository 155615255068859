import {
  erroSimulaParcelamento,
  simulaParcelamento,
  sucessoSimulaParcelamento,
} from 'src/app/core/store/parcelamento/parcelamento.actions';
import { RequestSimularParcelamento } from '../../../core/models/reqBody/parcelamento/reqSimulaParcelamento';
import { IParcelamentoState } from 'src/app/core/store/parcelamento/parcelamento.reducer';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DialogErrorComponent } from './../dialog-error/dialog-error.component';
import { takeUntil, Subject, take, distinctUntilChanged, finalize } from 'rxjs';
import { ClienteCompleto } from 'src/app/core/models/service/cadastroCliente';
import { ClienteService } from '../../observables/cliente.service';
import { Fornecimento } from 'src/app/core/models/fornecimento';
import { Fatura } from './../../../core/models/faturaCompleta';
import { Cliente } from 'src/app/core/models/cliente';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-parcel-debts',
  templateUrl: './parcel-debts.component.html',
  styleUrls: ['./parcel-debts.component.scss'],
})
export class ParcelDebtsComponent implements OnChanges, OnInit {
  // Inputs
  @Input() fornecimento: Fornecimento;
  @Input() faturas: Fatura[];
  @Input() cliente: Cliente;

  // Number
  valorFaturasAtraso: number = 0;
  valorFaturasAberto: number = 0;
  qtdFaturas: number = 0;

  cli: ClienteCompleto;
  faturasAtraso: Fatura[] = [];

  faturasAberto: Fatura[] = [];

  reqParcelamento: RequestSimularParcelamento =
    new RequestSimularParcelamento();

  debitos: number = 0;
  incluirAberto: boolean = false;
  setLoading: boolean;
  destroyed$ = new Subject<boolean>();
  protParcela: string = '';
  cliente$ = this.cliObs.getCliente();

  constructor(
    private store: Store<{ parcelamento: IParcelamentoState }>,
    private storageService: TokenStorageService,
    private cliObs: ClienteService,
    private updates$: Actions,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.loadCliente();
  }


  private loadCliente() {
    this.cliente$
      .pipe(take(1), distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((cli) => {
        this.cli = cli ?? new ClienteCompleto();
        this.montaParcelamento();
      });
  }

  montaParcelamento() {
    this.faturasAtraso = [];
    this.valorFaturasAtraso = 0;
    if (this.faturas?.length > 0) {
      this.faturas.forEach((fatura: Fatura) => {
        if (
          (fatura.situacaoDaFatura.toUpperCase() === 'EM ATRASO' &&
            !fatura.cobrancaJuridica &&
            !fatura.debitoAutomatico) ||
          (fatura.situacaoDaFatura.toUpperCase() === 'RESIDUAL A PAGAR' &&
            new Date(fatura.dataVencimento).getTime() < new Date().getTime())
        ) {
          this.faturasAtraso.push(fatura);
          this.qtdFaturas++;
          this.valorFaturasAtraso += fatura.valor;
        }
        this.debitos = this.valorFaturasAtraso + this.valorFaturasAberto;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.cli) {
      this.loadCliente();
    } else {
      this.montaParcelamento();
    }
  }

  ngOnInit(): void {
    this.setLoading = false;
  }

  changeCheckbox(event: any) {
    this.incluirAberto = !this.incluirAberto;
    if (event.target.checked) {
      this.faturas.forEach((fatura: Fatura) => {
        if (
          fatura.situacaoDaFatura.toUpperCase() === 'EM ABERTO' ||
          fatura.situacaoDaFatura.toUpperCase() === 'RESIDUAL A PAGAR'
        ) {
          this.faturasAberto.push(fatura);
          this.qtdFaturas++;
          this.valorFaturasAberto += fatura.valor;
        }
      });
      this.debitos = this.valorFaturasAtraso + this.valorFaturasAberto;
    } else {
      this.faturas.forEach((fatura: Fatura) => {
        if (
          fatura.situacaoDaFatura.toUpperCase() === 'EM ABERTO' ||
          fatura.situacaoDaFatura.toUpperCase() === 'RESIDUAL A PAGAR'
        ) {
          this.qtdFaturas--;
        }
      });
      this.faturasAberto = [];
      this.valorFaturasAberto = 0;
      this.debitos = this.valorFaturasAtraso;
    }
  }

  navigateToInstallment() {
    const forn = JSON.parse(`${localStorage.getItem('fezParc')}`);
    if (forn === undefined || this.checkList(forn)) {
      this.reqParcelamento = {
        fornecimento: this.fornecimento?.codigo,
        em_aberto: this.incluirAberto
      };
      this.setLoading = true;
      localStorage.setItem(
        'reqParcelamento',
        JSON.stringify(this.reqParcelamento)
      );
      this.store.dispatch(simulaParcelamento({ req: this.reqParcelamento }));
      this.updates$
        .pipe(
          ofType(sucessoSimulaParcelamento),
          takeUntil(this.destroyed$),
          finalize(() => {
            this.setLoading = false;
          }),
          take(1)
        )
        .subscribe({
          next: () => {
            if (
              localStorage.getItem('FluxoPJ') &&
              this.storageService.getTipoUsuario() === 'PF,PJ'
            ) {
              localStorage.setItem('isReparcelamento', 'false');
              this.redireciona('parcelamento');
            } else {
              localStorage.setItem('isReparcelamento', 'false');
              this.router.navigate(['/parcelamento']);
            }
          },
          error: (erro) => {
            console.error(erro);
          },
        });
      this.updates$
        .pipe(
          ofType(erroSimulaParcelamento),
          takeUntil(this.destroyed$),
          finalize(() => {
            this.setLoading = false;
          }),
          take(1)
        )
        .subscribe({
          next: (action: any) => {
            this.setLoading = false;
            if (action.status === 520 || action.status === 403) {
              this.dialog.open(DialogErrorComponent, {
                width: '475px',
                data: {
                  img: 'icon-validation-error',
                  title: `${action.error.provider.errorMessage}`,
                  content: `${action.error.description}`,
                  imgAnimation: false,
                },
                disableClose: true,
              });
            }
          },
          error: (err) => {},
        });
    } else {
      this.setLoading = false;
      this.dialog.open(DialogErrorComponent, {
        data: {
          img: 'icon_erro',
          title: 'Parcelamento Duplicado',
          content: `Já foi solicitado um parcelamento para esse fornecimento com o protocolo <b>${this.protParcela}</b>, caso queira consultar o andamento do processo acesse <a id="linkRedirect" href="/minhas-solicitacoes"<i><b><u>Minhas Solicitações</u></b></i></a>.`,
        },
        panelClass: `custom-dialog-error`,
        disableClose: true,
      });
    }
  }

  checkList(lista: any[]): boolean {
    if (lista !== undefined && lista !== null) {
      for (let i = 0; i < lista.length; i++) {
        if (lista[i].forn === this.fornecimento.codigo) {
          this.protParcela = lista[i].protocolo;
          return false;
        }
      }
    }
    return true;
  }

  pressSimula(e: any) {
    let key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.navigateToInstallment();
    }
  }

  verificarFaturaAberta(): boolean {
    let filtro: any = this.faturas.filter(
      (x) => x.situacaoDaFatura === 'EM ABERTO'
    );
    return filtro !== undefined && filtro.length > 0;
  }

  redireciona(link: string) {
    const rotaAtual = this.router.url;
    const segmentosRotaAtual: string[] = rotaAtual.split('/');
    segmentosRotaAtual.push(link);
    this.router.navigate(segmentosRotaAtual);
  }
}
