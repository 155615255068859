import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'agv-item-list',
  template: `<div [id]="id + '__agv-item-list'" class="agv-item-list">
    <p [id]="id + '__agv-item-list__desc'" class="agv-item-list__desc" [innerHTML]="descricao">
    </p>
    <div [id]="id + '__agv-item-list__btns'" class="agv-item-list__btns">
      <agv-button-edit
        [id]="id + '__agv-item-list__btns__edit'"
        class="agv-item-list__btns__edit"
        (sendAction)="actionEdit()"
      ></agv-button-edit>
      <agv-button-exc
        [id]="id + '__agv-item-list__btns__exc'"
        class="agv-item-list__btns__exc"
        (sendAction)="actionExclude()"
      ></agv-button-exc>
    </div>
  </div>`,
  styles: [
    `
      .agv-item-list {
        background: var(--color-white);
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        flex-direction: row;
        padding: 16px;
        display: flex;
        height: 78px;
        width: 100%;

        &__desc {
          font-size: 1.125rem;
          font-weight: 400;
          color: var(--color-black);
        }

        &__btns{
            flex-direction: row;
            display: flex;
            gap: 8px;
        }
    }
    `,
  ],
})
export class AgvItemListComponent {
  // Input String
  @Input() id: string;
  @Input() descricao: string;

  // Actions
  @Output() sendEdit = new EventEmitter<any>();
  @Output() sendExc = new EventEmitter<any>();

  // Função para emitir a ação de editar
  actionEdit() {
    this.sendEdit.emit();
  }

  // Função para emitir a ação de excluir
  actionExclude() {
    this.sendExc.emit();
  }
}
