<div [id]="id+'__date-picker'" class="date-picker">
  <label [id]="id+'__date-picker__label'" class="date-picker__label" [for]="'__date-picker__input'">{{label}}</label>
  <input [id]="id+'__date-picker__input'" class="date-picker__input" type="text" [placeholder]="placeholder" [value]="dateSelected"/>
  <agv-icon [id]="id+'__date-picker__icon'" class="date-picker__icon" [name]="'icn_data'" (click)="show = !show"></agv-icon>
  <div [id]="id+'__date-picker__calendar'" class="date-picker__calendar" [ngClass]="{'d-none' : !show }">
    <div [id]="id+'__date-picker__calendar__ma'" class="date-picker__calendar__ma">
      <agv-icon [id]="id+'__date-picker__calendar__ma__arrow-left'" class="date-picker__calendar__ma__arrow-left" [name]="'arrow_black'" (click)="prevMonth()"></agv-icon>
      <span [id]="id+'__date-picker__calendar__ma__month'" class="date-picker__calendar__ma__month">{{getMonth()}}</span>
      <span [id]="id+'__date-picker__calendar__ma__year'" class="date-picker__calendar__ma__year">{{getYear()}}</span>
      <agv-icon [id]="id+'__date-picker__calendar__ma__arrow-right'" class="date-picker__calendar__ma__arrow-right" [name]="'arrow_black'" (click)="nextMonth()"></agv-icon>
    </div>
    <div [id]="id+'__date-picker__calendar__wd'" class="date-picker__calendar__wd">
      <ul [id]="id+'__date-picker__calendar__wd__week'" class="date-picker__calendar__wd__week">
        <li>Dom</li>
        <li>Seg</li>
        <li>Ter</li>
        <li>Qua</li>
        <li>Qui</li>
        <li>Sex</li>
        <li>Sáb</li>
      </ul>
      <ul [id]="id+'__date-picker__calendar__wd__days'" class="date-picker__calendar__wd__days">
        <li *ngFor="let day of getDays()" [ngClass]="day.class" (click)="selectDate(day.value, day.prevMonth ? -1 : day.nextMonth ? 1 : 0)">
          {{day.value}}
        </li>
      </ul>
    </div>
  </div>
</div>