<div [id]="id + '__agv-table-content'" class="agv-table-content">
    <div [id]="id + '__agv-table-content__filtros'" class="agv-table-content__filtros">
        <div [id]="id + '__agv-table-content__filtros__grupo'" class="agv-table-content__filtros__grupo" *ngIf="showRowLimit">
            <p [id]="id + '__agv-table-content__filtros__grupo__label'" class="agv-table-content__filtros__grupo__label">Itens por página:</p>
            <ul [id]="id + '__agv-table-content__filtros__grupo__rows'" class="agv-table-content__filtros__grupo__rows">
                <li [id]="id + '__agv-table-content__filtros__grupo__rows__item-{{index}}'" class="agv-table-content__filtros__grupo__rows__item" *ngFor="let num of listLimitRow; let firt = first; let last = last; let index = index;" [ngClass]="{'first': firt, 'last': last, 'selected': rowLimit === num}" (click)="selectRowLimit(num)">
                    {{num < 10 ? '0' + num : num}}
                </li>
            </ul>
        </div>
        <div [id]="id + '__agv-table-content__filtros__busca'" class="agv-table-content__filtros__busca">
            <input [id]="id + '__agv-table-content__filtros__busca__input'" class="agv-table-content__filtros__busca__input" type="text" [placeholder]="placeholderBusca" (change)="filtraListaBusca()">
            <agv-icon [id]="id + '__agv-table-content__filtros__busca__icon'" class="agv-table-content__filtros__busca__icon" [name]="'icn_lupa'"></agv-icon>
        </div> 
    </div>
    <table [id]="id + '__agv-table-content__table'" class="agv-table-content__table">
        <thead [id]="id + '__agv-table-content__table__header'" class="agv-table-content__table__header">
            <th [id]="id + '__agv-table-content__table__header__column'" class="agv-table-content__table__header__column" *ngFor="let coluna of colunas; let index = index; let last = last;">
                <div [id]="id + '__agv-table-content__table__header__column__group'" class="agv-table-content__table__header__column__group">
                    <span [ngClass]="{'text-left': coluna.type === 'Text','text-middle': coluna.type !== 'Text'}">{{coluna.label}}</span>
                    <hr *ngIf="!last"/>
                </div>
            </th>
        </thead>
        <tbody [id]="id + '__agv-table-content__table__body'" class="agv-table-content__table__body">
            <tr [id]="id + '__agv-table-content__table__body__row'" class="agv-table-content__table__body__row" *ngFor="let linha of dados; let last = last;" [ngClass]="{'border-bottom': !last}">
                <td [id]="id + '__agv-table-content__table__body__row__column'" class="agv-table-content__table__body__row__column" *ngFor="let i of indice; let last2 = last;" [ngClass]="{'text-left': i.type === 'Text','text-middle': i.type !== 'Text'}">
                    <div [id]="id + '__agv-table-content__table__body__row__column__group'" class="agv-table-content__table__body__row__column__group">
                        <span *ngIf="i.type === 'Text'" class="text-left">
                            {{linha[i.index]}}
                        </span>
                        <span *ngIf="i.type === 'Int'">
                            {{linha[i.index]}}
                        </span>
                        <span *ngIf="i.type === 'Currency'">
                            {{formataCurrency(linha[i.index])}}
                        </span>
                        <span *ngIf="i.type === 'Date'">
                            {{formataDate(linha[i.index])}}
                        </span>
                        <span *ngIf="i.type === 'Toggle'" class="toggle-center">
                            <agv-slide-toggle [check]="linha[i.index]"></agv-slide-toggle>
                        </span>
                        <span *ngIf="i.type === 'LabelToggle'" class="toggle-center">
                            <agv-slide-toggle [check]="linha[i.index]" [textLabel]="labelToggle" [labelLeft]="true"></agv-slide-toggle>
                        </span>
                        <span *ngIf="i.type === 'ToggleLabel'" class="toggle-center">
                            <agv-slide-toggle [check]="linha[i.index]" [textLabel]="labelToggle" [labelLeft]="false"></agv-slide-toggle>
                        </span>
                        <span *ngIf="i.type === 'Button'">
                            <agv-button [text]="buttonText" (sendAction)="btnClick(linha)"></agv-button>
                        </span>
                        <span *ngIf="i.type === 'Edit'">
                            <agv-button-edit (sendAction)="editClick(linha)"></agv-button-edit>
                        </span>
                        <span *ngIf="i.type === 'Delete'">
                            <agv-button-exc (sendAction)="deleteClick(linha)"></agv-button-exc>
                        </span>
                        <span *ngIf="i.type === 'Edt&Del'">
                            <agv-button-edit (sendAction)="editClick(linha)"></agv-button-edit>
                            <agv-button-exc (sendAction)="deleteClick(linha)"></agv-button-exc>
                        </span>
                        <span *ngIf="i.type === 'Img'">
                            <img [src]="linha[i.index]" title="Imagem de anexada ao item" alt="Essa imagem está anexada ao item"/>
                        </span>
                        <span *ngIf="i.type === 'Checkbox'">
                            <input type="checkbox"/>
                        </span>
                        <hr *ngIf="!last2"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div [id]="id + '__agv-table-content__pages'" class="agv-table-content__pages">
        <span [id]="id + '__agv-table-content__pages__init'" class="agv-table-content__pages__init" (click)="primeiraPagina()">
            <agv-icon [name]="'arrow_black'"></agv-icon>
            <agv-icon [name]="'arrow_black'"></agv-icon>
        </span>
        <span [id]="id + '__agv-table-content__pages__previous'" class="agv-table-content__pages__previous" (click)="voltarPagina()">
            <agv-icon [name]="'arrow_black'"></agv-icon>
        </span>
        <span [id]="id + '__agv-table-content__pages__page'" class="agv-table-content__pages__page" *ngFor="let pagina of paginas; let index = index" [ngClass]="{'hidden' : pagina.pagina - paginaSelecionada >= 3 || pagina.pagina - paginaSelecionada < 0, 'selected' : pagina.pagina === paginaSelecionada}" (click)="selecionaPagina(pagina.pagina)">{{pagina.pagina}}</span>
        <span [id]="id + '__agv-table-content__pages__next'" class="agv-table-content__pages__next" (click)="proximaPagina()">
            <agv-icon [name]="'arrow_black'"></agv-icon>
        </span>
        <span [id]="id + '__agv-table-content__pages__last'" class="agv-table-content__pages__last" (click)="ultimaPagina()">
            <agv-icon [name]="'arrow_black'"></agv-icon>
            <agv-icon [name]="'arrow_black'"></agv-icon>
        </span>
    </div>
</div>
