import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NoticiaModel } from 'src/app/core/models/noticias.model';

@Component({
  selector: 'app-noticia-lista',
  templateUrl: './noticia-lista.component.html',
  styleUrls: ['./noticia-lista.component.scss'],
})
export class NoticiaListaComponent implements OnInit {
  // Input String
  @Input() txtButton: string = 'Saiba Mais';
  @Input() id: string;

  // Input Number
  @Input() type: number; // 1 - Botão simples | 2 - Botão editar e excluir

  
  // Input Object
  @Input() item: NoticiaModel;

  // Actions
  @Output() sendAction = new EventEmitter<any>();
  @Output() sendEdit = new EventEmitter<any>();
  @Output() sendExc = new EventEmitter<any>();

  // Contrutor do component
  constructor(){}

  // Função executada ao iniciar component
  ngOnInit(): void {
    
  }

  // Função para acionar função no pai
  action() {
    this.sendAction.emit();
  }
  // Função para acionar função no pai
  editar() {
    this.sendEdit.emit();
  }
  // Função para acionar função no pai
  excluir() {
    this.sendExc.emit();
  }
}
