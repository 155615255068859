
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SecurityCodeWarningComponent } from './security-code-warning/security-code-warning.component';
import { DadosPagamento } from 'src/app/core/models/condicaoPagamentoParcela';
import { CartaoCreditoInfo, CartaoEncriptar, ClienteInfoCredito, CorpoEfetivaPag, CorpoGerarTokenCliente, CorpoRetornoSimularParc, CorpoSimularParcCartao, CorpoValidacaoDados, EnderecoCredito, IObjectKeys, ListaCodeline, RetornoParcelas, TokenClienteCredito, ValoresMultaCartao } from 'src/app/core/models/pagamentoCartao';
import { CartaoServices } from 'src/app/services/cartaoCredito.service';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { Fatura } from 'src/app/core/models/faturaCompleta';
import { FornecimentoService } from '../../observables/fornecimento.service';
import { FaturaServices } from 'src/app/services/faturaServices.service';
import { combineLatest, of, Subject, switchMap, takeUntil } from 'rxjs';
import { InputSelect } from '../agv-material/agv-inputs/input-select/input-select.model';
import { ClienteService } from '../../observables/cliente.service';
import { ClienteCompleto } from 'src/app/core/models/service/cadastroCliente';
import { Fornecimento } from 'src/app/core/models/fornecimento';
import { Regex } from '../../utils/regex';
class Passo {
  passoAtual: string;
  passoAnterior: string | undefined;
  constructor() {
    this.passoAtual = '';
    this.passoAnterior = '';
  }
}
@Component({
  selector: 'app-cartao-dados-pagamento',
  templateUrl: './cartao-dados-pagamento.component.html',
  styleUrls: ['./cartao-dados-pagamento.component.scss']
})
export class CartaoDadosPagamentoComponent implements OnInit {
  @Input() fatura: Fatura | Fatura[];
  @Input() valorSelecaoFatura: number;

  public destroy$: Subject<any> = new Subject<any>();
  private unsubscribe$ = new Subject<void>();

  mensagemFluxoFinalizado: { imagem: string, titulo: string, mensagem: string };
  corpoValidacaoCartaoCredito: CorpoValidacaoDados;
  cartaoSalvoSelecionado: CartaoEncriptar | null
  novoCartaoInformacoes: CartaoEncriptar;
  listaCartoes: CartaoEncriptar[] = [];
  listaCodeline: ListaCodeline[] = [];

  listaParcelamento: RetornoParcelas[] = [new RetornoParcelas()];
  corpoEnvioPagamento: CorpoEfetivaPag = new CorpoEfetivaPag();
  dadosPagamento: DadosPagamento = new DadosPagamento();
  structorSelect: InputSelect = new InputSelect();
  valoresMulta: ValoresMultaCartao;
  step: Passo = new Passo();

  tokenCliente: string;
  codCliente: string;
  protocolo: string;
  opCartao: string = '';

  habilitaNovoCartao: boolean = false;
  salvarCartao: boolean = false;
  loading: boolean = false;
  fluxoConcluido: boolean;

  faturaValor: number;

  bandeirasCartao: IObjectKeys = {
    mastercard: 'MASTER',
    visa: 'VISA',
    elo: 'ELO',
    diners: 'DINERS',
    hipercard: 'HIPERCARD'
  };

  constructor(
    private tokenService: TokenStorageService,
    private clienteService: ClienteService,
    private cartaoService: CartaoServices,
    private faturaService: FaturaServices,
    private fornecimentoService: FornecimentoService,
    public modal: MatDialog,
  ) {
    const token = this.tokenService.getToken();
    token ? this.tokenCliente = token : '';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.montaStructorCartao();
    this.carregaListaCartao();
    this.capturaInformacoesCliente();
    this.capturaInformacoesFatura();
    this.fluxoConcluido = false;
  }

  montaStructorCartao() {
    this.structorSelect.label = 'Crédito';
    this.structorSelect.placeholder = 'Crédito';
  }

  capturaInformacoesFatura(): void {
    if (Array.isArray(this.fatura)) {
      this.faturaValor = this.valorSelecaoFatura;
      this.fatura.forEach(fatura => {
        this.corpoEnvioPagamento.faturas.push(fatura.codigoPagamento);
        this.listaCodeline.push({
          codeline: fatura.codigoPagamento,
          debitoOriginal: fatura.valor
        })
      })
    } else {
      this.corpoEnvioPagamento.faturas.push(this.fatura.codigoPagamento)
      this.faturaValor = this.fatura.valor;
      this.listaCodeline.push({
        codeline: this.fatura.codigoPagamento,
        debitoOriginal: this.faturaValor
      })
    }
    this.carregaOpçoesPagamento();
  }

  capturaInformacoesCliente(): void {
    this.fornecimentoService.currentFornecimento.subscribe(fornecimento => fornecimento ? this.corpoEnvioPagamento.codigoFornecimento = fornecimento.codigo : '')
    const codCliente = this.tokenService.getCliente();
    codCliente ? this.codCliente = codCliente.codigo : '';
  }

  carregaOpçoesPagamento() {
    const corpoSimular: CorpoSimularParcCartao = {
      codigoFornecimento: this.corpoEnvioPagamento.codigoFornecimento,
      codigoSujeito: this.codCliente,
      listaCodeline: this.listaCodeline
    };
    this.cartaoService.atualizaValoresParcelamento(this.tokenCliente, corpoSimular).pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (data: CorpoRetornoSimularParc) => {
        this.faturaValor = data.valorAtualizado
        this.valoresMulta = new ValoresMultaCartao(data.valorAtualizado, data.valorATM, data.valorJuros, data.valorMulta, data.valorOriginal, data.valorTRCF);
        this.listaParcelamento = data.parcelas.map(parcela => {
          return parcela
        })
      },
      error: () => { },
      complete: () => { }
    });
  }

  // habilitaCredito(): boolean{
  //   if (Array.isArray(this.fatura)){
  //     return this.fatura.filter(x => x.situacaoDaFatura.toUpperCase() != "EM ATRASO" || this.calcularDiasDiferenca(x.dataVencimento) < 60).length == 0;
  //   } else {
  //     return (
  //       this.fatura.situacaoDaFatura.toUpperCase() == "EM ATRASO" 
  //       && this.calcularDiasDiferenca(this.fatura.dataVencimento) > 60);
  //   }
  // }

  calcularDiasDiferenca(data: Date) {
    const dataHoje: Date = new Date();
    return (dataHoje.getTime() - new Date(data).getTime()) / (1000 * 3600 * 24)
  }

  carregaListaCartao() {
    this.cartaoService.capturaListaCartao(this.tokenCliente).subscribe({
      next: (data: CartaoEncriptar[]) => {
        data.map((cartao: CartaoEncriptar) => {
          this.listaCartoes.push(cartao)
        })
      },
      error: () => { },
      complete: () => {
        this.defineStepInicial();
      },
    })
  }

  defineStepInicial() {
    this.listaCartoes.length > 0 ? this.step = {
      passoAtual: 'selecionaCartao',
      passoAnterior: ''
    } : this.step = {
      passoAtual: 'insereDadosNovoCartao',
      passoAnterior: ''
    }
  }

  capturaDadosCartaoInserido(e: CartaoEncriptar) {
    this.novoCartaoInformacoes = e;
  }

  capturaTokenCartaoInserido(e: string) {
    this.corpoEnvioPagamento.tokenCartao = e;
  }

  capturaDadosMtdPagamento(e: any) {
    this.corpoEnvioPagamento.parcelas = e.parcelas.parcelas;
    this.dadosPagamento.parcelas = e.parcelas.label;
    this.dadosPagamento.valor = this.faturaValor;
    this.corpoEnvioPagamento.valorAtualizado = this.faturaValor;
  }

  capturaCartaoSalvoSelecionado(e: CartaoEncriptar | null) {
    this.cartaoSalvoSelecionado = e;
    if (this.cartaoSalvoSelecionado && this.cartaoSalvoSelecionado.idCartao) {
      this.novoCartaoInformacoes = this.cartaoSalvoSelecionado;
      this.corpoEnvioPagamento.idCartao = this.cartaoSalvoSelecionado.idCartao;
    }
  }

  realizarPagamento() {
    this.loading = true;
    this.geraTokenCliente();
    // this.corpoEnvioPagamento.salvarCartao = this.salvarCartao;
    // this.cartaoService.efetuaPagamentoCartao(this.tokenCliente, this.corpoEnvioPagamento).subscribe({
    //   next: (data) => {
    //     this.loading = false;
    //     this.protocolo = data.protocolo,
    //       this.mensagemFluxoFinalizado = {
    //         imagem: 'sucesso',
    //         titulo: '<b>Solicitação concluída</b>',
    //         mensagem: 'Seu pagamento foi realizado com sucesso. O reconhecimento do pagamento em nosso sistema pode levar até 3 dias úteis. <p>  Foi gerado o protocolo de atendimento  nº' + this.protocolo + '</p>'
    //       }
    //     this.faturaService.listarFaturasPorFornecimento(this.corpoEnvioPagamento.codigoFornecimento).subscribe(() => {
    //       console.info("Faturas atualizadas");
    //     });
    //   },
    //   error: () => {
    //     this.loading = false;
    //     this.fluxoConcluido = true;
    //     this.mensagemFluxoFinalizado = this.mensagemFluxoFinalizado = {
    //       imagem: 'erro',
    //       titulo: "<b style='color: var(--color-failed)'>Falha no pagamento</b>",
    //       mensagem: 'Ocorreu uma falha no pagamento. Por favor, tente novamente mais tarde ou use outra forma de pagamento. Caso o problema persista, entre em contato com a operadora do cartão.'
    //     };
    //   },
    //   complete: () => {
    //     this.loading = false;
    //     this.fluxoConcluido = true;
    //   }
    // })
  }

  geraTokenCliente() {
    const corpoGeraTokenCliente: CorpoGerarTokenCliente = new CorpoGerarTokenCliente(this.corpoEnvioPagamento.codigoFornecimento, this.bandeirasCartao[this.novoCartaoInformacoes.brand]);
    this.cartaoService
      .geraTokenCliente(corpoGeraTokenCliente).pipe(
        takeUntil(this.destroy$),
        switchMap((data: TokenClienteCredito) => {
          this.montaCorpoReqValidaCartao()
          if (data && data.AccessToken) {
            return this.cartaoService.verificaCartaoCliente(data, this.corpoValidacaoCartaoCredito)
          } else {
            // Se não houver fornecimento no resultado, retorne um Observable vazio ou faça algum tratamento de erro.
            return of(null);
          }
        }),
        takeUntil(this.destroy$)
      ).subscribe({
        next: () => {
        },
        error: () => {
        }
      }
      )
  }

  montaCorpoReqValidaCartao() {
    combineLatest([
      this.clienteService.getCliente(),
      this.fornecimentoService.currentFornecimento
    ])
      .pipe(
        switchMap(([cliente, fornecimento]) => {
          const dadosCombinados: { cliente: ClienteCompleto | undefined, fornecimento: Fornecimento | undefined } = { cliente, fornecimento };
          return of(dadosCombinados); // Retornando um Observable com os dados combinados
        }),
        takeUntil(this.unsubscribe$)
      ).subscribe({
        next: (dados: { cliente: ClienteCompleto | undefined, fornecimento: Fornecimento | undefined }) => {
          let endereco = new EnderecoCredito(dados.fornecimento?.endereco, dados.fornecimento?.numero, dados.fornecimento?.complemento, String(dados.fornecimento?.cep), dados.fornecimento?.cidade, dados.fornecimento?.bairro);
          let cliente = new ClienteInfoCredito(`${dados.cliente?.nome} ${dados.cliente?.sobrenome}`, dados.cliente?.cpf, dados.cliente?.email, Regex.formataNascimentoToUS(dados.cliente?.dataNascimento!), dados.cliente?.celular, endereco)
          let cartao = new CartaoCreditoInfo(Regex.removeEspacos(this.novoCartaoInformacoes.number!), this.novoCartaoInformacoes.holder, `${this.novoCartaoInformacoes.exp_month}/${this.novoCartaoInformacoes.exp_year}`, this.novoCartaoInformacoes.securityCode, this.bandeirasCartao[this.novoCartaoInformacoes.brand]);
          this.corpoValidacaoCartaoCredito = new CorpoValidacaoDados(cliente, cartao);
        }, error: () => {
          this.corpoValidacaoCartaoCredito = new CorpoValidacaoDados();
        }
      })
  }

  // relativo à mudança de fluxo, liberação de fluxo e abertura de modal
  changeStep() {
    switch (this.step.passoAtual) {
      case 'selecionaCartao':
        if (this.cartaoSalvoSelecionado !== null) {
          this.step = {
            passoAtual: 'insereMtdoPagCartaoSalvo',
            passoAnterior: 'selecionaCartao'
          }
        } else {
          this.step = {
            passoAtual: 'insereDadosNovoCartao',
            passoAnterior: 'selecionaCartao'
          }
        }
        break
      case 'insereDadosNovoCartao':
        this.step = {
          passoAtual: 'checkoutPagamento',
          passoAnterior: 'insereDadosNovoCartao'
        }
        break;
      case 'insereMtdoPagCartaoSalvo':
        this.step = {
          passoAtual: 'checkoutPagamento',
          passoAnterior: 'insereMtdoPagCartaoSalvo'
        }
        break;
      case 'checkoutPagamento':
        this.realizarPagamento();
        break;
      default:
        break;
    }
  }

  capturaRetornPasso(e: string) {
    if (e === 'mtdoPagamento') {
      this.step.passoAnterior === 'insereDadosNovoCartao' ? this.step = {
        passoAtual: this.step.passoAnterior,
        passoAnterior: 'selecionaCartao'
      } : this.step = {
        passoAtual: 'insereMtdoPagCartaoSalvo',
        passoAnterior: 'selecionaCartao'
      }
    } else {
      this.corpoEnvioPagamento.idCartao = '';
      this.corpoEnvioPagamento.tokenCartao = '';
      this.step.passoAnterior === 'insereDadosNovoCartao' ? this.step = {
        passoAtual: this.step.passoAnterior,
        passoAnterior: 'selecionaCartao'
      } : this.step = {
        passoAtual: 'selecionaCartao',
        passoAnterior: ''
      }
    }
  }

  showInfo() {
    this.modal.open(SecurityCodeWarningComponent, {
      width: '90%',
      maxWidth: '475px',
      panelClass: 'modal-aviso',
    });
  }

  enderecoPopup(e: any) {
    let key = e.which || e.keyCode;
    if (key == 13 || key == 32) {
      this.showInfo();
    }
  }

  habilitaBotao(): boolean {
    let habilita = true;
    switch (this.step.passoAtual) {
      // case 'selecionaCartao':
      //   habilita = this.cartaoSalvoSelecionado !== undefined;
      //   return !habilita;
      case 'insereDadosNovoCartao':
        habilita = this.novoCartaoInformacoes !== undefined && this.corpoEnvioPagamento.parcelas != 0 && this.corpoEnvioPagamento.tokenCartao !== undefined;
        return !habilita;
      case 'insereMtdoPagCartaoSalvo':
        habilita = this.dadosPagamento.parcelas != '';
        return !habilita;
      case 'checkoutPagamento':
        return false;
      default:
        return true;
    }
  }

}







