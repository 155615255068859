<section
  id="noticia"
  class="noticias"
  *ngIf="noticiasCards && noticiasCards.length > 0"
>
  <div role="group" class="noticias__cards">
    <div role="group" class="noticias__cards__header" tabindex="0">
      <h2>Sabesp <span>INFORMA</span></h2>
      <p>Confira as novidades da Sabesp</p>
    </div>
    <app-noticia-lista
      *ngFor="let noticia of noticiasCards"
      [item]="noticia"
      (sendAction)="abrirNoticia(noticia)"
      [txtButton]="'Saiba Mais'"
      [type]="1"
    ></app-noticia-lista>
  </div>
</section>
