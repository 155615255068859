import {
  SimpleChanges,
  Component,
  OnChanges,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'agv-input-mult-select',
  template: `<div [id]="id + '__input-mult-select'" class="input-mult-select">
    <label
      [id]="id + '__input-mult-select__label'"
      class="input-mult-select__label"
      [for]="id + '__input-mult-select__options__input-serach'"
    >
      {{ label }}
    </label>
    <div
      [id]="id + '__input-mult-select__group'"
      class="input-mult-select__group"
    >
      <div
        [id]="id + '__input-mult-select__selected'"
        class="input-mult-select__selected"
      >
        <span
          [id]="id + '__input-mult-select__selected__placeholder'"
          class="input-mult-select__selected__placeholder"
          *ngIf="selected.length <= 0"
        >
          {{ placeholder }}
        </span>
        <span
          *ngFor="let value of selected; let index = index"
          [id]="id + '__input-mult-select__selected__item-{{index}}'"
          class="input-mult-select__selected__item"
        >
          {{ value.descricao }}
          <agv-icon
            [name]="'icn-remove'"
            (click)="remove(value.id, value.code)"
          ></agv-icon>
        </span>
      </div>
    </div>
    <div
      [id]="id + '__input-mult-select__options'"
      class="input-mult-select__options"
      [ngClass]="{ 'd-none': !opened }"
    >
      <input
        type="text"
        [id]="id + '__input-mult-select__options__input-serach'"
        class="input-mult-select__options__input-serach"
        maxlength="50"
        [placeholder]="placeholderSearch ?? 'Busque aqui...'"
        [(ngModel)]="search"
        (ngModelChange)="filtrar()"
      />
      <ul
        [id]="id + '__input-mult-select__options__itens'"
        class="input-mult-select__options__itens"
      >
        <li
          *ngFor="let option of optionsFilter; let index2 = index"
          [id]="id + '__input-mult-select__options__itens__item-{{index2}}'"
          class="input-mult-select__options__itens__item"
          [ngClass]="{ selected: itemSelecionado(option) }"
          (click)="selecionar(option)"
        >
          {{ option.descricao }}
        </li>
      </ul>
    </div>
    <agv-icon
      [id]="id + '__input-mult-select__icon'"
      class="input-mult-select__icon"
      [ngClass]="{ opened: opened }"
      [name]="'arrow_black'"
      (click)="opened = !opened"
    ></agv-icon>
  </div>`,
  styles: [
    `
      .input-mult-select {
        border: 1px solid var(--color-gray-weak);
        background: var(--color-white);
        flex-direction: column;
        position: relative;
        border-radius: 4px;
        padding: 10px 20px;
        display: flex;
        height: 65px;

        &__label {
          font-size: 0.75rem;
          font-weight: 400;
        }

        &__icon {
          transition: all 0.2s;
          position: absolute;
          cursor: pointer;
          height: 16px;
          width: 16px;
          right: 20px;
          top: 25px;

          &.opened {
            transform: rotate(180deg);
          }
        }

        &__group {
          max-height: 44px;
          overflow: hidden;
        }

        &__selected {
          flex-direction: row;
          position: absolute;
          font-size: 0.75rem;
          padding-top: 4px;
          flex-wrap: wrap;
          overflow: auto;
          display: flex;
          height: 33px;
          width: 90%;
          gap: 10px;

          &__placeholder {
            margin-top: 5px;
          }

          &__item {
            border: 1px solid var(--color-gray-weak);
            backgroud: var(--color-white);
            border-radius: 4px;
            position: relative;
            width: fit-content;
            padding: 5px 10px;
            display: flex;

            agv-icon {
              position: absolute;
              cursor: pointer;
              width: 10px;
              height: 10px;
              right: -5px;
              top: -5px;
            }
          }

          &::-webkit-scrollbar-track {
            background-color: var(--color-webkit-scrollbar);
            border-radius: 150px;
            height: 50px;
            width: 5px;
          }

          &::-webkit-scrollbar {
            border-radius: 150px;
            height: 50px;
            width: 13px;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--color-webkit-scrollbar-background);
            border: 3px solid var(--color-webkit-scrollbar);
            border-radius: 150px;
            height: 30px;
            width: 5px;
          }
        }

        &__options {
          border: 1px solid var(--color-gray-weak);
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
          background: var(--color-white);
          flex-direction: column;
          position: absolute;
          max-height: 200px;
          border-top: 0px;
          height: 100vh;
          display: flex;
          padding: 10px;
          width: 100%;
          z-index: 1;
          top: 65px;
          left: 0;

          &.d-none {
            display: none;
          }

          &__input-serach {
            border: 1px solid var(--color-gray-weak);
            border-radius: 4px;
            position: absolute;
            padding: 0 10px;
            height: 35px;
            width: 97%;
          }

          &__itens {
            margin-top: 34px;
            cursor: pointer;
            overflow: auto;

            &::-webkit-scrollbar-track {
              background-color: var(--color-webkit-scrollbar);
              border-radius: 150px;
              height: 50px;
              width: 5px;
            }

            &::-webkit-scrollbar {
              border-radius: 150px;
              height: 50px;
              width: 13px;
            }

            &::-webkit-scrollbar-thumb {
              background: var(--color-webkit-scrollbar-background);
              border: 3px solid var(--color-webkit-scrollbar);
              border-radius: 150px;
              height: 30px;
              width: 5px;
            }

            &__item {
              border-top: 1px solid var(--color-gray-weak);
              padding: 10px;

              &.selected {
                background: var(--color-primary);
                color: var(--color-white);
              }
            }
          }
        }
      }
    `,
  ],
})
export class AgvInputMultSelect implements OnInit, OnChanges, AfterViewInit {
  
  // Input String
  @Input() placeholderSearch: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() id: string;

  // Input Lista
  @Input() options: MultSelectModel[] = [];
  @Input() value: MultSelectModel[];

  // lista
  optionsFilter: MultSelectModel[] = [];
  selected: MultSelectModel[] = [];

  // String
  search: string;

  // Boolean
  opened: boolean;

  // Actions
  @Output() sendValores = new EventEmitter<MultSelectModel[]>();

  ngOnInit(): void {
    this.optionsFilter = this.options;
    this.selected = this.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue !== changes['value']?.previousValue) {
      this.selected = this.value;
    }
  }

  // Função para remover o item selecionado
  remove(id: string, code: string) {
    this.selected = this.selected.filter((m) => m.id !== id && m.code !== code);
    this.sendValores.emit(this.selected);
  }

  // Função para selecionar o item
  selecionar(option: MultSelectModel) {
    if (this.selected.find((m) => m.id === option.id) === undefined) {
      this.selected.push(option);
      this.optionsFilter = this.options;
      this.search = '';
    }
    this.sendValores.emit(this.selected);
  }

  // Função para retornar se o item foi selecionados
  itemSelecionado(option: MultSelectModel): boolean {
    return this.selected.find((m) => m.id === option.id) !== undefined;
  }

  // Função para filtrar pela descrição
  filtrar() {
    this.optionsFilter = this.options;
    if (this.search !== undefined && this.search !== '') {
      this.optionsFilter = this.options.filter((m) =>
        m.descricao.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  }

  ngAfterViewInit(): void {
    this.selected = this.value;
  }
   
}

export class MultSelectModel {
  id: string;
  code: string;
  descricao: string;
}
